import UrlSvgComponent from "@assets/svg/custom/url";
import CallSvgComponent from "@assets/svg/phone";
import ReplySvgComponent from "@assets/svg/whatsapp-reply";
import { replacePlaceholders } from "@common/markdown/markdown-helper";
import { capitalizeFirstLetterOfEachWord } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import { Button, Empty, Image, Radio, Tag, Typography } from "antd";
import { config } from "env";
import MarkdownPreview from "@uiw/react-markdown-preview";
import { convertCurrentDate } from "@common/date-helpers";
import { CheckCircleFilled } from "@ant-design/icons";
import ListLoader from "@components/common/Loader";
import { Plus } from "lucide-react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { useRef } from "react";

const TemplateSelector = ({
  isLoading1,
  request,
  handleTemplateRequest,
  templateData,
}: any) => {
  const swiperRef = useRef(null);

  return (
    <>
      {/* <h6 className="header_text__20 mx-5 px-5">Select Template</h6> */}
      <div className="mb-3 mx-5 px-5">
        <h6 className="header_text__20">Select Template</h6>
        <p className="small_text__14">What message do you want to send?</p>
      </div>
      {isLoading1 ? (
        <ListLoader />
      ) : templateData.length === 0 ? (
        <Empty
          className="border rounded-3 mt-4 py-5"
          // imageStyle={{ height: 60 }}
          description={<Typography.Text>No templates found</Typography.Text>}
        >
          <Button size="large" type="primary" icon={<Plus size={18} />}>
            Create New Template
          </Button>
        </Empty>
      ) : (
        <div className="">
          <Radio.Group
            defaultValue="a"
            size="large"
            className="w-100 custom-broadcast-selector my-4 gap-3 mx-auto"
            value={getValue(request, `templateId`, "")}
            // onChange={(e: any) => handleTemplateRequest(e.target.value)}

            onChange={(e: any) => {
              const selectedId = e.target.value;
              const selectedItem = templateData.find(
                (item: any) => getValue(item, "id", "") === selectedId
              );
              const selectedName = selectedItem
                ? getValue(selectedItem, "name", "")
                : "";
              const selectedBody = selectedItem
                ? getValue(selectedItem, "body", "")
                : "";
              handleTemplateRequest(selectedId, selectedName, selectedBody);
            }}
          >
            {templateData.map((item: any, index: any) => (
              <Radio.Button
                value={getValue(item, "id", "")}
                className=" p-0"
                key={index}
              >
                <div className="template-radio-wrapper">
                  <div>
                    <div className="p-3 bg-white rounded-top-3 ">
                      <h6 className="header_text__16 mb-2 position-relative pe-4">
                        {capitalizeFirstLetterOfEachWord(
                          getValue(item, "name", "")
                        )}
                        <div className="position-absolute top-0 end-0">
                          {getValue(request, `templateId`, "") === item.id && (
                            <CheckCircleFilled
                              style={{ color: "#1A73E8", fontSize: "18px" }}
                            />
                          )}
                        </div>
                      </h6>

                      <div className="d-flex align-items-center gap-1">
                        <Tag color="green">{getValue(item, "status", "")}</Tag>{" "}
                        <span className="small_text__12">
                          {getValue(item, "category", "")}
                        </span>
                      </div>
                    </div>
                    <div className="template-message-container py-4">
                      <div className="mx-4 bg-white p-3 template-message-body">
                        <MarkdownPreview
                          source={replacePlaceholders(
                            getValue(item, `body`, "")
                          )}
                          className="mb-3"
                        />
                        {getValue(item, `header_type`, "") === "IMAGE" &&
                          getValue(item, `header_file`, "") && (
                            <div className="d-flex justify-content-center align-items-center mb-2">
                              <img
                                src={`https://${config.URL}/${getValue(
                                  item,
                                  `header_file.key`,
                                  ""
                                )}`}
                                alt="Selected"
                                className="w-100 object-fit-cover rounded-3"
                                height={120}
                              />
                            </div>
                          )}
                        {getValue(item, `header_type`, "") === "VIDEO" &&
                          getValue(item, `header_file`, "") && (
                            <div className="d-flex justify-content-center align-items-center mb-3">
                              <video
                                autoPlay
                                loop
                                controls
                                style={{ height: "100px", width: "100%" }}
                              >
                                <source
                                  src={`https://${config.URL}/${getValue(
                                    item,
                                    `header_file.key`,
                                    ""
                                  )}`}
                                  type="video/mp4"
                                />
                              </video>
                            </div>
                          )}
                        {getValue(item, `header_type`, "") === "DOCUMENT" && (
                          <div className="d-flex justify-content-center align-items-center">
                            <img
                              src="/images/icons/document-blue.svg"
                              className="dummy-image-icon"
                            />
                          </div>
                        )}
                        {getValue(item, `buttons`, []) &&
                          getValue(item, "buttons", []).map(
                            (button: any, btnIndex: number) => (
                              <div key={btnIndex} className="">
                                <div className="d-flex justify-content-center align-items-center gap-2 broadcast_button">
                                  {getValue(button, "type", "") ===
                                  "QUICK_REPLY" ? (
                                    <ReplySvgComponent
                                      color="#408dfb"
                                      size={16}
                                    />
                                  ) : getValue(button, "type", "") ===
                                    "CALL_TO_ACTION" ? (
                                    <CallSvgComponent
                                      color="#408dfb"
                                      size={14}
                                    />
                                  ) : getValue(button, "type", "") === "URL" ? (
                                    <UrlSvgComponent
                                      color="#408dfb"
                                      size={14}
                                    />
                                  ) : null}

                                  <p className="small_text__13 text-black">
                                    {getValue(button, "text", "")}
                                  </p>
                                </div>
                              </div>
                            )
                          )}
                      </div>
                      {getValue(item, "category", "").length > 0 && (
                        <>
                          <div className="broadcast-carousel-swiper_wrapper mx-4 mt-2">
                            <Swiper
                              spaceBetween={10}
                              slidesPerView={1.25}
                              onSlideChange={() => console.log("slide change")}
                              modules={[Navigation]}
                              navigation={{
                                prevEl: ".custom-button-prev",
                                nextEl: ".custom-button-next",
                              }}
                              ref={swiperRef}
                              className="position-relative"
                            >
                              {getValue(item, "carousels", "").map(
                                (carousel: any, index: number) => (
                                  <SwiperSlide key={index}>
                                    <div className="carousel-swiper-card">
                                      {getValue(carousel, "header_type", "") ===
                                      "IMAGE" ? (
                                        <img
                                          src={`https://${
                                            config.URL
                                          }/${getValue(
                                            carousel,
                                            "header_file.key",
                                            ""
                                          )}`}
                                          alt="Carousel Image"
                                          className="broadcast-carousel-image"
                                        />
                                      ) : (
                                        <video
                                          autoPlay
                                          className="broadcast-carousel-video"
                                        >
                                          <source
                                            src={`https://${
                                              config.URL
                                            }/${getValue(
                                              carousel,
                                              "header_file.key",
                                              ""
                                            )}`}
                                            type="video/mp4"
                                          />
                                        </video>
                                      )}
                                      <p className="p-2 small_text__13 text-black">
                                        {getValue(carousel, "body", "")}
                                      </p>
                                      <div
                                        className={`ms-2 d-flex flex-column gap-1`}
                                      >
                                        {getValue(carousel, "buttons", []).map(
                                          (button: any, btnIndex: number) => (
                                            <div key={btnIndex}>
                                              <div className="d-flex justify-content-center align-items-center broadcast_button">
                                                {getValue(
                                                  button,
                                                  "type",
                                                  ""
                                                ) === "QUICK_REPLY" ? (
                                                  <ReplySvgComponent
                                                    color="#408dfb"
                                                    size={16}
                                                  />
                                                ) : getValue(
                                                    button,
                                                    "type",
                                                    ""
                                                  ) === "CALL_TO_ACTION" ? (
                                                  <CallSvgComponent
                                                    color="#408dfb"
                                                    size={14}
                                                  />
                                                ) : getValue(
                                                    button,
                                                    "type",
                                                    ""
                                                  ) === "URL" ? (
                                                  <UrlSvgComponent
                                                    color="#408dfb"
                                                    size={14}
                                                  />
                                                ) : null}

                                                <p className="ms-2 small_text__12 text-black">
                                                  {getValue(button, "text", "")}
                                                </p>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </SwiperSlide>
                                )
                              )}
                            </Swiper>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between p-3 bg-white rounded-bottom-3">
                    <Tag bordered={false} color="blue">
                      {capitalizeFirstLetterOfEachWord(
                        getValue(item, "language", "")
                      )}
                    </Tag>
                    <p className="small_text__13">
                      {convertCurrentDate(getValue(item, "created_at", ""))}
                    </p>
                  </div>
                </div>
              </Radio.Button>
            ))}
          </Radio.Group>
        </div>
      )}
    </>
  );
};

export default TemplateSelector;
