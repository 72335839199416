import CountrySearchToolTip from "@components/custom/Dropdown/CountrySearchTooltip";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import InputRuleForm from "@components/InputRuleForm/form";
import { getValue } from "@utils/lodash";
import { Radio, Space } from "antd";
import React from "react";
import countries from "@json/dialcodes.json";

const ScheduleBroadcast = ({
  request,
  setRequest,
  timestamp,
  setTimestamp,
  scheduleTimestamp,
  setScheduleTimestamp,
  allConnectionList,
}: any) => {
  return (
    <div className="">
      <div className="mb-3 mx-5 px-5">
        <h6 className="header_text__20">Add Details </h6>
        <p className="small_text__14">
          Select account and add a broadcast name
        </p>
      </div>
      <div
        className="mx-auto"
        style={{
          maxWidth: "900px",
        }}
      >
        <div className="mt-3 mb-2">
          <SearchToolTip
            label="label"
            data={allConnectionList}
            selectKey={"value"}
            value={getValue(request, `waAccountId`, "")}
            onChange={(e: any) =>
              setRequest((prevState: any) => ({
                ...prevState,
                waAccountId: getValue(e, `id`, ""),
              }))
            }
            name="Select Whatsapp Account"
            placeholder="Choose Whatsapp Account"
            showWhatsappIcon
            required
          />
        </div>
        <InputRuleForm
          label="Broadcast Name"
          inputType="TEXT"
          name="name"
          placeholder="Enter Broadcast Name"
          required
          className="mb-2"
          onChange={(e: any) =>
            setRequest((prevState: any) => ({
              ...prevState,
              name: e.target.value,
            }))
          }
          value={getValue(request, `name`, "")}
        />

        <CountrySearchToolTip
          label={"label"}
          data={countries.map((item: object) => ({
            ...item,
            label: getValue(item, "dial_code", ""),
            value: getValue(item, "dial_code", ""),
          }))}
          name="Country Code"
          selectKey="dial_code"
          value={getValue(request, `countryCode`, "")}
          onChange={(e: any) =>
            setRequest((prevState: any) => ({
              ...prevState,
              countryCode: getValue(e, `value`, ""),
            }))
          }
          width={"900px"}
        />
      </div>

      <div className="mb-3 mx-5 px-5 mt-5">
        <h6 className="header_text__20">When do you want to send it? </h6>
      </div>
      <div
        className="mt-3 mx-auto"
        style={{
          maxWidth: "900px",
        }}
      >
        <Radio.Group
          onChange={(e) => {
            setTimestamp(e.target.value);
            if (e.target.value === "now") {
              setScheduleTimestamp(new Date());
            }
          }}
          value={timestamp}
        >
          <Space direction="vertical">
            <Radio value="now">Send now</Radio>
            <Radio value="later">Schedule for a specific time</Radio>
          </Space>
        </Radio.Group>
        <div className="w-50">
          {timestamp === "later" && (
            <InputRuleForm
              inputType="DATETIME"
              placeholder="Select Time"
              value={scheduleTimestamp}
              onChange={(e: any) => setScheduleTimestamp(e)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ScheduleBroadcast;
