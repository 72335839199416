import "./support-form.scss";
import { Link, useNavigate } from "react-router-dom";

import * as React from "react";
import { getValue } from "@utils/lodash";
import { handleEmailChange, handleTextChange } from "common/handleChange";
import InfoSvgComponent from "@assets/svg/info";

interface ISupportFormProps {
  // handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  request: {
    identifier: string;
    password: string;
  };
  handleSubmit: (e: React.FormEvent) => void;
  loading: boolean;
  setRequest: any;
  submitLoading: boolean;
  simpleValidator: any;
}
const SupportForm: React.FunctionComponent<ISupportFormProps> = (props) => {
  const { request, setRequest } = props;
  const [visibility, setVisisbility] = React.useState(false);
  const navigate = useNavigate();
  return (
    <>
      <div className="d-flex justify-content-center ">
        <div className={`support-form-wrapper position-relative`}>
          {/* <div className={`support-form-group form-group`}> */}
          <form autoComplete="on">
            <div
              className={`signup-form-wrapper__form-group support-form-group form-group mt-5`}
            >
              {/* <label className={"support-label"}>
            Email address
          </label> */}
              <div className="position-relative">
                <input
                  type="text"
                  name="identifier"
                  value={getValue(props, `request.identifier`, "")}
                  onChange={(e) => handleEmailChange(e, request, setRequest)}
                  placeholder="Enter Your Email"
                  // className={`support-form-control form-control`}
                  className={`custom-signup-form-control form-control`}
                  // autoComplete="email"
                  autoComplete="on"
                />
                <img
                  src={`/images/icons/${getValue(
                    props,
                    `icon`,
                    "person"
                  )}-icon.svg`}
                  className={`image-icon img-fluid`}
                />
              </div>
              {props.simpleValidator && (
                <p className={`form-error`}>
                  {props.simpleValidator.current.message(
                    "Email",
                    getValue(props, `request.identifier`, ""),
                    "required|email"
                  )}
                </p>
              )}
            </div>
            <div
              className={`signup-form-wrapper__form-group support-form-group form-group mb-0 mt-3`}
            >
              {/* <label className={"support-label"}>Password</label> */}
              <div className="position-relative">
                <input
                  type={!visibility ? "password" : "text"}
                  placeholder="Enter Password"
                  name="password"
                  value={getValue(props, `request.password`, "")}
                  onChange={(e) => handleTextChange(e, request, setRequest)}
                  className={`custom-signup-form-control form-control`}
                  // autoComplete="current-password"
                  autoComplete="on"
                />
                <img
                  src={`/images/icons/key-icon.svg`}
                  className={`image-icon img-fluid`}
                />
                <img
                  src={
                    !visibility
                      ? `/images/visibility_off.svg`
                      : `/images/visibility.svg`
                  }
                  className={"visbility_icon"}
                  onClick={() => setVisisbility(!visibility)}
                />
              </div>
              {props.simpleValidator && (
                <p className={`form-error d-flex`}>
                  {/* <InfoSvgComponent color="#d93025" size="12px"/> */}
                  {props.simpleValidator.current.message(
                    "Password",
                    getValue(props, `request.password`, ""),
                    "required"
                  )}
                </p>
              )}
            </div>
          </form>

          <div className="d-flex justify-content-between">
            <div
              className={"forgot-password"}
              onClick={() =>
                navigate(
                  `/forgot-password-email?email=${getValue(
                    request,
                    `identifier`,
                    ""
                  )}`
                )
              }
            >
              <a>Forgot your password?</a>
            </div>
            <div
              className={"forgot-password"}
              onClick={() =>
                navigate(
                  `/signin-with-email?email=${getValue(
                    request,
                    `identifier`,
                    ""
                  )}`
                )
              }
            >
              <a>Login with OTP?</a>
            </div>
          </div>
          <button
            className="superteams-button__primary-button transition "
            onClick={props.handleSubmit}
            disabled={getValue(props, `submitLoading`, false)}
          >
            <div className="d-flex align-items-center justify-content-center">
              {getValue(props, `submitLoading`, false) && (
                <>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    style={{ color: "white" }}
                    aria-hidden="true"
                  ></span>
                  <p className="ms-2"></p>
                </>
              )}

              {getValue(props, `submitLoading`, false)
                ? "Please wait..."
                : "Login"}
            </div>
          </button>
        </div>
      </div>
    </>
  );
};

export default SupportForm;
