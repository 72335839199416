import FormBuilder from "@components/Builder/FormBuilder";
import { getValue } from "@utils/lodash";
import { Button, Modal } from "antd";
import { useState } from "react";

function ConvertContactToLeadPopup(props: any) {
  const {
    form,
    handleModal,
    setForm,
    fields,
    setFields,
    handleSubmitConvert,
    simpleValidator,
    convertLoading,
  } = props;

  const [duplicateAPINames, setDuplicateAPINames] = useState<any>([]);
  return (
    <Modal
      open={getValue(props, `isOpen`, "")}
      onCancel={handleModal}
      onOk={handleModal}
      width={1000}
      className="custom-modal-height" // Apply custom class here
      footer={[
        <Button key="cancel" onClick={handleModal} className="cancel__button">
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleSubmitConvert}
          disabled={convertLoading}
          className="submit__button"
          loading={convertLoading}
        >
          Convert
        </Button>,
      ]}
    >
      <div className="border-bottom">
        <h6>Convert to Customer</h6>
      </div>
      <div className="modal-body-scrollable">
        <div className="d-flex align-items-start gap-2">
          <div>
            <h6 className="header_text__16 color_primary">Leads Form</h6>
            <FormBuilder
              fields={
                getValue(form, `length`, 0) > 0
                  ? form.filter((item: object) => getValue(item, `value`, ""))
                  : []
              }
              setFields={setForm}
              disabled
              duplicateAPINames={duplicateAPINames}
              setDuplicateAPINames={setDuplicateAPINames}
            />
          </div>
          <div>
            <h6 className="header_text__16 color_primary">Contacts Form</h6>
            <FormBuilder
              fields={fields}
              setFields={setFields}
              duplicateAPINames={duplicateAPINames}
              setDuplicateAPINames={setDuplicateAPINames}
              simpleValidator={simpleValidator}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ConvertContactToLeadPopup;
