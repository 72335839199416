import { getValue } from "@utils/lodash";
import { useParams } from "react-router-dom";
import { usePermissionContext } from "@context/permissionContext";

const CompanyNavigationList = () => {
  const params = useParams();
  const { tabPermissionList, subTabPermissionList } = usePermissionContext();
  const CompanyNavigationListArray = [
    {
      title: "General",
      name: "settings",
      linkArray: [
        {
          linkTitle: "Company Profile",
          linkUrl: `/${getValue(params, `orgId`, "")}/settings/company-profile`,
          path: "company-profile",
          image_url: "/images/company_profile.svg",
          name: "settings_general_company_profile",
        },
        {
          linkTitle: "Branding",
          linkUrl: `/${getValue(params, `orgId`, "")}/settings/branding`,
          path: "branding",
          image_url: "/images/branding.svg",
          name: "settings_general_branding",
        },
      ],
    },
    {
      title: "Customization",
      name: "settings",
      linkArray: [
        {
          linkTitle: "Modules and Fields",
          linkUrl: `/${getValue(
            params,
            `orgId`,
            ""
          )}/settings/module-and-field`,
          path: "module-and-field",
          image_url: "/images/modules_fields.svg",
          name: "settings_customisation_modules_and_fields",
        },
        {
          linkTitle: "Emails",
          linkUrl: `/${getValue(params, `orgId`, "")}/settings/emails/config`,
          path: "emails",
          image_url: "/images/emails.svg",
          name: "settings_customisation_emails",
        },
      ],
    },
    {
      title: "Organisation",
      name: "settings",
      linkArray: [
        {
          linkTitle: "Users",
          linkUrl: `/${getValue(params, `orgId`, "")}/settings/users`,
          path: "users",
          image_url: "/images/users.svg",
          name: "settings_organisation_users",
        },
        {
          linkTitle: "Roles",
          linkUrl: `/${getValue(params, `orgId`, "")}/settings/roles`,
          path: "roles",
          image_url: "/images/users.svg",
          name: "settings_organisation_roles",
        },
      ],
    },
    {
      title: "Inbox",
      name: "settings",
      linkArray: [
        {
          linkTitle: "WhatsApp",
          linkUrl: `/${getValue(params, `orgId`, "")}/settings/messages`,
          path: "whatsapp",
          image_url: "/images/emails.svg",
          name: "settings_inbox_whatsapp",
        },
      ],
    },
    {
      title: "Marketing",
      name: "settings",
      linkArray: [
        {
          linkTitle: "Web Forms",
          linkUrl: `/${getValue(params, `orgId`, "")}/settings/forms`,
          path: "forms",
          image_url: "/images/forms.svg",
          name: "settings_marketing_web_forms",
        },
        {
          linkTitle: "Ad Leads",
          linkUrl: `/${getValue(params, `orgId`, "")}/settings/ads`,
          path: "ads",
          image_url: "/images/ads.svg",
          name: "settings_marketing_ad_leads",
        },
      ],
    },
    {
      title: "Automation",
      name: "settings",
      linkArray: [
        {
          linkTitle: "Workflows",
          linkUrl: `/${getValue(params, `orgId`, "")}/settings/workflows`,
          path: "workflows",
          image_url: "/images/emails.svg",
          name: "settings_automation_workflows",
        },
      ],
    },
    {
      title: "Reports",
      name: "settings",
      linkArray: [
        {
          linkTitle: "Bulk Download",
          linkUrl: `/${getValue(
            params,
            `orgId`,
            ""
          )}/settings/data-administration-exports`,
          path: "data-administration-exports",
          image_url: "/images/emails.svg",
          name: "settings_automation_workflows",
        },
      ],
    },
  ];

  const filterNavigationList = () => {
    // Filter the main tabs where `name` matches any value in `tabPermissionList`
    const filteredTabs = CompanyNavigationListArray.filter((tab) =>
      tabPermissionList.includes(tab.name)
    );

    // For each filtered tab, filter its `linkArray` based on `subTabPermissionList`
    const updatedTabs = filteredTabs.map((tab) => ({
      ...tab,
      linkArray: tab.linkArray.filter((link: any) =>
        subTabPermissionList.includes(link.name)
      ),
    }));

    return updatedTabs;
  };
  return filterNavigationList();
};

export default CompanyNavigationList;
