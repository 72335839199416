import Layout from "@layouts/Layout";
import React, { useEffect, useState } from "react";
import { listOfOrganisations } from "@services/organisations.service";
import { getValue } from "@utils/lodash";
import { useNavigate } from "react-router-dom";
import { convertCurrentDate, getStartOfMonthDate } from "common/date-helpers";
import Nodata from "@components/common/NoData/NoData";
import { useStateContext } from "@context/profileProvider";
import "./organisation.scss";
import { concatString, getOnlyFirstLetterOfWord } from "@common/text-helpers";
import TooltipOnHover from "@components/common/Tooltip/tooltip-on-hover";
import ListLoader from "@components/common/Loader";
import { getModernColorCode } from "@common/colors";
import { Avatar } from "antd";
import SearchHeader from "@components/custom/SearchHeader";
import Search from "@components/custom/Search";
interface IOrganisationsProps {}

const Organisations: React.FunctionComponent<IOrganisationsProps> = (props) => {
  const navigate = useNavigate();
  useEffect(() => {
    getOrganisations();
  }, []);
  const { userInfo, handleChangeOrgainization }: any = useStateContext();
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(true);
  const [organisations, setOrgList] = useState([]);
  const getOrganisations = async () => {
    try {
      setIsLoading(true);
      let resp = await listOfOrganisations("");
      if (resp) {
        if (getValue(resp, `data.length`, 0) === 0) {
          navigate(`/organisations/create`);
        }
        setOrgList(getValue(resp, `data`, []));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleNavigate = (code: string) => {
    let date_range = getStartOfMonthDate();
    // localStorage.setItem("X-Org-Code", code);

    // navigate(`/${code}/contacts`);
    handleChangeOrgainization();
    // window.location.href = `/${code}/dashboard?date_range=${date_range}`;
    window.location.href = `/${code}/home`;
  };

  // const [selectedIndex, setSelectedIndex] = useState(0);
  // const [uploadLoading, setUploadLoading] = useState(false);
  // const handleUploadDocuments = async (
  //   event: any,
  //   code: string,
  //   index: number
  // ) => {
  //   setSelectedIndex(index);
  //   try {
  //     const file = event.target.files[0];
  //     if (!file) {
  //       console.error("No file selected.");
  //       return;
  //     }
  //     const formData = new FormData();
  //     formData.append("file", file);
  //     formData.append("filename", file.name);
  //     formData.append("is_public", "true");
  //     formData.append("collection", "public-images");
  //     setUploadLoading(true);
  //     const resp = await mediaUploadWithCode(formData, code);
  //     if (resp) {
  //       const response = await updateOrganisationsWithCode(
  //         {
  //           logo_id: getValue(resp, `data.id`, ""),
  //         },
  //         code
  //       );
  //       if (response) {
  //         toast.success("Imported successfully");
  //         getOrganisations();
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error uploading file:", error);
  //   } finally {
  //     setUploadLoading(false);
  //   }
  // };

  // const getColorCode = (alphabet: any) => {
  //   const colorCodes: any = {
  //     A: "#D93025",
  //     B: "#FF8C00",
  //     C: "#FFDD00",
  //     D: "#008000",
  //     E: "#0064B0",
  //     F: "#6A0DAD",
  //     G: "#8C1D40",
  //     H: "#EC4C47",
  //     I: "#FBBC04",
  //     J: "#009587",
  //     K: "#00A8C1",
  //     L: "#0433FF",
  //     M: "#D500F9",
  //     N: "#DB4437",
  //     O: "#F4B400",
  //     P: "#0F9D58",
  //     Q: "#00A4FF",
  //     R: "#616161",
  //     S: "#9E9E9E",
  //     T: "#8D6E63",
  //     U: "#795548",
  //     V: "#BDBDBD",
  //     W: "#607D8B",
  //     X: "#FF8A65",
  //     Y: "#FF80AB",
  //     Z: "#FF4081",
  //   };
  //   // Convert input to uppercase
  //   alphabet = alphabet.toUpperCase();
  //   // Check if the alphabet exists in the dictionary
  //   if (alphabet in colorCodes) {
  //     return colorCodes[alphabet];
  //   } else {
  //     return null; // Return null if no color code is found for the input
  //   }
  // };

  /* -------------------------------------------------------------------------- */
  /*                               Search section                               */
  /* -------------------------------------------------------------------------- */
  const [showSearch, setShowSearch] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filteredOrganizations, setFilteredOrganizations] = useState([]);

  const toggleSearch = () => {
    setShowSearch(!showSearch);
    if (showSearch === true) {
      setSearchInput("");
      setFilteredOrganizations(organisations);
    }
  };
  const handleSearch = (e: any) => {
    const query = e.target.value.toLowerCase();
    setSearchInput(query);
    const filteredData = organisations.filter((org: any) =>
      org.name.toLowerCase().includes(query)
    );
    setFilteredOrganizations(filteredData);
  };

  useEffect(() => {
    setFilteredOrganizations(organisations);
  }, [organisations]);

  return (
    <Layout>
      <div className={`d-flex w-100`}>
        <div className={"organisations-wrapper__main-section-wrapper"}>
          <div className={`organisations-wrapper__main-section`}>
            <div
              className={`organisations-create`}
              onClick={() => navigate(`/organisations/create`)}
            >
              <div></div>
              <div className={`organisations-create_button`}>
                <p className="">Create New Organisation</p>
              </div>
            </div>
            <div className={`separator`}></div>

            {getValue(organisations, `length`, 0) > 0 && (
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h5 className={`headertext d-flex align-items-center`}>
                    Hi{" "}
                    <p className="text_blue__18 mx-2">
                      {getValue(userInfo, `first_name`, "")}{" "}
                      {getValue(userInfo, `last_name`, "")},{" "}
                    </p>
                  </h5>
                  <p className={"desctext"}>
                    You belong to the following organisations. Please select the
                    organisation you wish to access now
                  </p>
                </div>
                <Search
                  searchInput={searchInput}
                  showSearchTextbox={showSearch}
                  toggleSearch={toggleSearch}
                  // toggleSearchClose={clearSearch}
                  toggleSearchClose={toggleSearch}
                  handleChangeSearch={handleSearch}
                  searchPlaceholder="Search organization"
                />
              </div>
            )}
            {isLoading ? (
              <ListLoader />
            ) : (
              <>
                {getValue(organisations, `length`, 0) > 0 ? (
                  <div>
                    {filteredOrganizations.map(
                      (item: object, index: number) => {
                        return (
                          <div
                            className={`organisations-box_container row`}
                            key={index}
                          >
                            <div
                              className={`organisations-left_container col-md-7 align-items-center`}
                            >
                              {/* {getValue(item, `logo.url`, "") ? (
                              index === selectedIndex && uploadLoading ? (
                                <div className={"branding-input"}>
                                  <p className="mt-4">Uploadlong...</p>
                                </div>
                              ) : (
                                <label
                                  htmlFor={`branding-logo${index}`}
                                  className="cursor-pointer"
                                >
                             <input
                                    type="file"
                                    className="d-none"
                                    id={`branding-logo${index}`}
                                    onChange={(e) =>
                                      handleUploadDocuments(
                                        e,
                                        getValue(item, `code`, ""),
                                        index
                                      )
                                    }
                                  /> 

                                  <div className={"branding-input"}>
                                    {getValue(item, `logo.url`, "") ? (
                                      <img
                                        src={getValue(item, `logo.url`, "")}
                                        className={`img-fluid cursor-pointer`}
                                      />
                                    ) : (
                                      <img
                                        src={"/nodata/chat.svg"}
                                        className={`img-fluid cursor-pointer`}
                                      />
                                    )}
                                  </div>
                                </label>
                              )
                            ) : index === selectedIndex && uploadLoading ? (
                              <div className={"branding-input"}>
                                <p className="mt-4">Uploadlong...</p>
                              </div>
                            ) : (
                              <label
                                htmlFor={`branding-logo${index}`}
                                className="cursor-pointer"
                              >
                               <input
                                  type="file"
                                  className="d-none"
                                  id={`branding-logo${index}`}
                                  onChange={(e) =>
                                    handleUploadDocuments(
                                      e,
                                      getValue(item, `code`, ""),
                                      index
                                    )
                                  }
                                /> 
                                <div className={"branding-input"}>
                                  <img
                                    src={"/nodata/building.jpeg"}
                                    width={200}
                                    className={`img-fluid cursor-pointer`}
                                  />
                                </div>
                              </label>
                            )} */}
                              {getValue(item, `logo.url`, "") ? (
                                <Avatar
                                  src={getValue(item, `logo.url`, "")}
                                  size={64}
                                />
                              ) : (
                                <Avatar
                                  style={{
                                    backgroundColor:
                                      getModernColorCode(
                                        getOnlyFirstLetterOfWord(
                                          getValue(item, `name`, "")
                                        )
                                      ) || "#fde3cf",
                                  }}
                                  size={64}
                                >
                                  <h6 className="header_text__18">
                                    {getOnlyFirstLetterOfWord(
                                      getValue(item, `name`, "")
                                    )}
                                  </h6>
                                </Avatar>
                              )}
                              <div
                                className={"organisations-left_text_container"}
                              >
                                {/* <div
                                className="image-container"
                                style={{
                                  background: getColorCode(
                                    getOnlyFirstLetterOfWord(
                                      getValue(item, `name`, "")
                                    )
                                  ),
                                  color: getTextColor(
                                    getColorCode(
                                      getOnlyFirstLetterOfWord(
                                        getValue(item, `name`, "")
                                      )
                                    )
                                  ),
                                }}
                              >
                                <p className="header-text">
                                  {getOnlyFirstLetterOfWord(
                                    getValue(item, `name`, "")
                                  )}
                                </p>
                              </div> */}
                                <TooltipOnHover
                                  header={
                                    <p className={`organisations-title`}>
                                      {concatString(
                                        getValue(item, `name`, ""),
                                        30
                                      )}
                                    </p>
                                  }
                                  text={getValue(item, `name`, "")}
                                />
                                <p className={`organisations-primary_title`}>
                                  Organisation ID : {getValue(item, `code`, "")}
                                </p>
                                <p className={`organisations-secondary_title`}>
                                  Organisation created on :{" "}
                                  {convertCurrentDate(
                                    getValue(item, `created_at`, "")
                                  )}
                                </p>
                              </div>
                            </div>
                            <div
                              className={`organisations-right_container col-md-5`}
                            >
                              <h6
                                className={`organisations-right_header_text`}
                                onClick={() =>
                                  handleNavigate(getValue(item, `code`, ""))
                                }
                              >
                                Go to this Organisation
                              </h6>
                              {/* <div
                              className={`organisations-right_header_arrow`}
                            >
                              <img
                                src="/images/more_horiz.svg"
                                className={`]} img-fluid`}
                              />
                            </div> */}
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                ) : (
                  <div>
                    <Nodata />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Organisations;
