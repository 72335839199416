import { getValue } from "@utils/lodash";
import { config } from "env";
import MarkdownPreview from "@uiw/react-markdown-preview";
import moment from "moment";
import CallSvgComponent from "@assets/svg/phone";
import UrlSvgComponent from "@assets/svg/custom/url";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { useRef } from "react";
import ReplySvgComponent from "@assets/svg/whatsapp-reply";
import { Empty, Image } from "antd";
import { SmileOutlined } from "@ant-design/icons";
import {
  replaceDefaultPlaceholders,
  replaceDefaultPlaceholdersBySingleLine,
  replacePlaceholders,
} from "@common/markdown/markdown-helper";

function WhatsappIconViewPage(props: any) {
  const { request } = props;
  const swiperRef = useRef(null);

  const replacePlaceholderHelper = (val: string) => {
    let text = replaceDefaultPlaceholdersBySingleLine(val);
    if (text)
      getValue(request, `customTags`, []).forEach((item: object) => {
        text =
          text &&
          text.replace(
            getValue(item, `placeholder`, ""),
            getValue(item, `sample`, "")
              ? getValue(item, `sample`, "")
              : getValue(item, `placeholder`, "")
          );
      });
    return text;
  };

  return (
    <div
      className="mobile-template"
      style={{
        textAlign: "left",
        fontFamily:
          '"SF Pro Text", "SF Pro Icons", system, -apple-system, system-ui, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", "Arial", "Lucida Grande", "Kohinoor Devanagari", sans-serif',
      }}
    >
      <div className="mobile-border">
        <div className="dark-green-header"></div>
        <div className="green-header"></div>
        <div className="mobile-screen">
          <div className="d-flex justify-content-center">
            <p className="meta-message">
              <img src="/images/icons/info.svg" /> This business uses a secure
              service from Meta to manage this chat. Tap to learn more
            </p>
          </div>
          <div className="message-box sdsd ">
            {getValue(request, `header.type`, "") === "TEXT" && (
              <p className="message-title small_text__14 text-black">
                {getValue(request, `header.text`, "")}
              </p>
            )}

            {getValue(request, `header.type`, "") === "IMAGE" &&
              getValue(request, `header.link.key`, "") && (
                <div className="d-flex justify-content-center align-items-center">
                  <Image
                    src={
                      getValue(request, `header.link.key`, "")
                        ? `https://${config.URL}/${getValue(
                            request,
                            `header.link.key`,
                            ""
                          )}`
                        : `https://${config.URL}/${getValue(
                            request,
                            `header.link`,
                            ""
                          )}`
                    }
                    alt="Selected"
                    className="main_image"
                  />
                </div>
              )}

            {getValue(request, `header.type`, "") === "VIDEO" &&
              (getValue(request, `header.link.key`, "") ||
                getValue(request, `header.link`, "")) && (
                <div className="d-flex justify-content-center align-items-center">
                  <video controls style={{ height: "100px", width: "100%" }}>
                    <source
                      src={`https://${config.URL}/${
                        getValue(request, `header.link.key`, "")
                          ? getValue(request, `header.link.key`, "")
                          : getValue(request, `header.link`, "")
                      }`}
                      type="video/mp4"
                    />
                  </video>
                  {/* <img
                    src={
                      getValue(request, `header.link.key`, "")
                        ? `https://${config.URL}/${getValue(
                            request,
                            `header.link.key`,
                            ""
                          )}`
                        : `https://${config.URL}/${getValue(
                            request,
                            `header.link`,
                            ""
                          )}`
                    }
                    alt="Selected"
                    className="main_image"
                  /> */}
                </div>
              )}

            {getValue(request, `header.type`, "") === "DOCUMENT" &&
              (getValue(request, `header.link.key`, "") ||
                getValue(request, `header.link`, "")) && (
                <div className="d-flex justify-content-center align-items-center">
                  {/* <img
                                  src={`https://${config.URL}/${getValue(
                                    request,
                                    `header.link.key`,
                                    ""
                                  )}`}
                                  alt="Selected"
                                  className="main_image"
                                /> */}
                  <img
                    src="/images/icons/document-blue.svg"
                    className="dummy-image-icon"
                  />
                </div>
              )}

            <div className="mt-2">
              {/* <p
                    className="small_text__14 text-black"
                    contentEditable="true"
                    dangerouslySetInnerHTML={{
                      __html: replacePlaceholderHelper(
                        getValue(request, `body`, "")
                      ),
                    }}
                  ></p> */}
              <MarkdownPreview
                source={replacePlaceholderHelper(getValue(request, `body`, ""))}
                // style={{ padding: 16 }}
              />
            </div>
            <div className="d-flex justify-content-between align-items-center mt-3">
              <p className="small_text__12 ">
                {getValue(request, `footer`, "")}
              </p>
              <div
              // className="d-flex justify-content-end"
              >
                <span className="small_text__10 ">
                  {moment().format("hh:mm a")}
                </span>
              </div>
            </div>

            {/* {getValue(request, `buttonType`, "") === "CALL_TO_ACTION" && (
                <>
                  <div className=" w-100">
                    {getValue(request, `buttons[${0}].callAction`, []).map(
                      (item: any) => {
                        if (
                          getValue(item, `type`, "") === "url" &&
                          getValue(item, `text`, "")
                        )
                          return (
                            <div className="d-flex justify-content-center gap-1 align-items-center link-message-button">
                              <OpenNewSvgComponent color="#408dfb" size={14} />
                              <p className="small_text__12 color_primary">
                                {getValue(item, `text`, "")}
                              </p>
                            </div>
                          );
                        if (
                          getValue(item, `type`, "") === "call" &&
                          getValue(item, `text`, "")
                        )
                          return (
                            <div className="d-flex justify-content-center gap-1 align-items-center phone-message-button">
                              <CallSvgComponent color="#408dfb" size={14} />
                              <p className="small_text__12 color_primary">
                                {getValue(item, `text`, "")}
                              </p>
                            </div>
                          );
                      }
                    )}
                  </div>
                </>
              )} */}
          </div>
          <div
            className="grid-container ms-2 mt-1 mb-3"
            style={{ width: "83%" }}
          >
            {getValue(request, `buttons`, []).map(
              (item: object, index: number) => {
                return (
                  getValue(item, `text`, "") && (
                    <div
                      className="d-flex justify-content-center align-items-center template_button"
                      key={index}
                      style={index === 2 ? { gridColumn: "span 3" } : {}}
                    >
                      {getValue(item, `action_type`, "") ===
                      "QUICK_REPLY" ? null : getValue(
                          item,
                          `action_type`,
                          ""
                        ) === "CALL_TO_ACTION" ? (
                        <CallSvgComponent color="#408dfb" size={14} />
                      ) : (
                        <UrlSvgComponent color="#408dfb" size={14} />
                      )}
                      <p className="ms-2 small_text__13">
                        {getValue(item, `text`, "")}
                      </p>
                    </div>
                  )
                );
              }
            )}
          </div>

          {/* <div className="action_div"> */}
          {/* {getValue(request, `buttonType`, "") === "QUICK_REPLY" && (
                <div className="d-flex align-items-center flex-wrap">
                  {getValue(request, `buttons[${0}].quickReply`, []).map(
                    (item: any) => {
                      if (getValue(item, `text`, ""))
                        return (
                          <div className="d-flex justify-content-center gap-1 align-items-center template_button">
                            {getValue(item, `text`, "")}
                          </div>
                        );
                    }
                  )}
                </div>
              )} */}
          {/* </div> */}
          {/* {getValue(request, "carousel", []).map((item: any, index: number) => {
            return <div key={index}>1</div>;
          })} */}

          {getValue(request, "category", "") === "CAROUSEL" && (
            <>
              <div className="carousel-swiper_wrapper">
                <Swiper
                  spaceBetween={10}
                  slidesPerView={1.25}
                  onSlideChange={() => console.log("slide change")}
                  modules={[Navigation]}
                  navigation={{
                    prevEl: ".custom-button-prev",
                    nextEl: ".custom-button-next",
                  }}
                  ref={swiperRef}
                  className="position-relative"
                >
                  <button
                    className="custom-button-prev"
                    aria-label="previous button"
                  >
                    <img
                      src="/images/arrow_forward.svg"
                      width={16}
                      height={16}
                      alt=""
                    />
                  </button>
                  <button
                    className="custom-button-next"
                    aria-label="next button"
                  >
                    <img
                      src="/images/arrow_forward.svg"
                      width={16}
                      height={16}
                      alt=""
                    />
                  </button>
                  {getValue(request, "carousel", "").map(
                    (item: any, index: number) => (
                      <SwiperSlide key={index}>
                        <div className="carousel-swiper-card">
                          {getValue(item, "header.type", "") === "IMAGE" ? (
                            <>
                              {getValue(item, "header.link.key", "") ||
                              getValue(item, "header.link", "") ? (
                                <Image
                                  src={`https://${config.URL}/${
                                    getValue(item, "header.link.key", "") ||
                                    getValue(item, "header.link", "")
                                  }`}
                                  alt="Carousel Image"
                                  className="carousal-image-preview w-100"
                                />
                              ) : (
                                <img
                                  src="/property/dummy-image.jpg"
                                  className="carousal-image-preview w-100"
                                  alt=""
                                />
                              )}
                            </>
                          ) : (
                            <>
                              {getValue(item, "header.link.key", "") ||
                              getValue(item, "header.link", "") ? (
                                <video
                                  autoPlay
                                  className="carousal-video-preview w-100"
                                >
                                  <source
                                    src={`https://${config.URL}/${
                                      getValue(item, "header.link.key", "") ||
                                      getValue(item, "header.link", "")
                                    }`}
                                    type="video/mp4"
                                  />
                                </video>
                              ) : (
                                <Empty description="Video not found" />
                              )}
                            </>
                          )}
                          <p className="p-2 small_text__13 text-black">
                            {getValue(item, "body", "")}
                          </p>
                          <div
                            className={`ms-2  ${
                              getValue(item, "buttons", []).length >= 2 &&
                              "grid-container "
                            } ${
                              getValue(item, "buttons", []).length > 1 &&
                              "border_top"
                            }`}
                          >
                            {getValue(item, "buttons", []).map(
                              (button: any, btnIndex: number) => (
                                <div key={btnIndex}>
                                  <div className="d-flex justify-content-center align-items-center template_button">
                                    {getValue(button, "action_type", "") ===
                                    "QUICK_REPLY" ? (
                                      <ReplySvgComponent
                                        color="#408dfb"
                                        size={16}
                                      />
                                    ) : getValue(button, "action_type", "") ===
                                      "CALL_TO_ACTION" ? (
                                      <CallSvgComponent
                                        color="#408dfb"
                                        size={14}
                                      />
                                    ) : getValue(button, "action_type", "") ===
                                      "URL" ? (
                                      <UrlSvgComponent
                                        color="#408dfb"
                                        size={14}
                                      />
                                    ) : null}

                                    <p className="ms-2 small_text__12 text-black">
                                      {getValue(button, "text", "")}
                                    </p>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default WhatsappIconViewPage;
