import "./task-left.scss";
import BackSvgComponent from "assets/svg/back-link";
import { useNavigate, useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import { convertCurrentDateWithTime } from "common/date-helpers";
import { concatString, formatString } from "common/text-helpers";
import TaskInfoPopup from "../MiddleSection/components/info-popup";
import TooltipOnHover from "@components/common/Tooltip/tooltip-on-hover";
import AccountCircleSvgComponent from "@assets/svg/account-circle";
import { Tag, Tooltip } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import { useState } from "react";
import SortTooltip from "@components/custom/Dropdown/sortTooltip";
import { QueryRequestHelper } from "@common/query-request-helper";

function TaskDetailLeftSection(props: any) {
  const params = useParams();
  const navigate = useNavigate();
  const { list } = props;

  const handleNavigate = () => {
    navigate(`/${getValue(params, `orgId`, "")}/crm/tasks`);
  };

  const handleClick = (id: string) => {
    navigate(`/${getValue(params, `orgId`, "")}/crm/tasks/detail/${id}`);
  };

  const sortOptions = [
    { value: "asc", label: "Asc" },
    { value: "desc", label: "Desc" },
  ];

  const [sort_by, setSortBy] = useState({
    field: "",
    value: "asc",
  });

  const getFilterName = () => {
    let filter =
      getValue(localStorage, `s:tasks`, "") &&
      JSON.parse(getValue(localStorage, `s:tasks`, ""));
    return getValue(filter, `field`, "") ? getValue(filter, `field`, "") : "";
  };

  const getFilterValue = () => {
    let filter =
      getValue(localStorage, `s:tasks`, "") &&
      JSON.parse(getValue(localStorage, `s:tasks`, ""));
    return getValue(filter, `value`, "") ? getValue(filter, `value`, "") : "";
  };

  const handleSelectValue = (name: any, value: any) => {
    localStorage.setItem(
      `s:tasks`,
      JSON.stringify({
        field: name,
        value: value,
      })
    );
    props.getListData();
    // let payload = {
    //   ...UrlParams,
    //   page_no: 1,
    //   smart_filter: true,
    // };
    // let queryRequest = QueryRequestHelper(payload);
    // navigate(`${window.location.pathname}?${queryRequest}`);
  };

  return (
    <div className={"task_detail_left-container"}>
      <div
        className={`${"task_detail_left-header_section"} d-flex align-items-center px-3 justify-content-between`}
      >
        <div className="d-flex align-items-center">
          <div onClick={handleNavigate}>
            <BackSvgComponent />
          </div>
          <h6 className="header_text ms-3">All Tasks</h6>
        </div>
        <Tooltip title="Sort due date">
          <div>
            <SortTooltip
              title={`Sort`}
              value={"created_at" === getFilterName() ? getFilterValue() : ""}
              label={`label`}
              onChange={(e: any) => {
                handleSelectValue("created_at", getValue(e, `value`, ""));
                setSortBy({
                  ...sort_by,
                  value: getValue(e, `value`, ""),
                });
              }}
              sortSelected={"created_at" === getFilterName() ? true : false}
              data={sortOptions}
              selectKey={"value"}
              editInline
              sortDateIcon
            />
          </div>
        </Tooltip>
      </div>
      <div
        className={
          !props.isChanged
            ? "task_detail_left-list-update-section"
            : "task_detail_left-list"
        }
      >
        {getValue(list, `length`, 0) > 0
          ? list.map((item: any) => {
              return (
                <div
                  className={`${`p-3 task_detail_left-list_section${
                    getValue(params, `id`, "") === getValue(item, `id`, "")
                      ? "__active"
                      : ""
                  }`} cursor-pointer mt-1`}
                  onClick={() => handleClick(getValue(item, `id`, ""))}
                >
                  <div className="d-flex align-items-center justify-content-between">
                    {/* <p className="header_text__14 ms-2">
                      {concatString(getValue(item, `title`, ""), 40)}
                    </p> */}
                    <div className="d-flex align-items-center ms-2">
                      {/* <TaskSvgComponent size={22} color={'lightgray'}/> */}
                      <TaskInfoPopup
                        header={
                          <p
                            className={`header_text__14 ms-2 ${
                              !getValue(item, `is_completed`, false)
                                ? ""
                                : "task-line-through"
                            }`}
                          >
                            {concatString(getValue(item, `title`, ""), 30)}
                          </p>
                        }
                        text={getValue(item, `title`, "")}
                        minLength={30}
                      />
                    </div>

                    <TooltipOnHover
                      header={
                        <div
                          className="filter-image-container"
                          onClick={() =>
                            props.handleTaskUpdate(
                              getValue(item, `id`, ""),
                              !getValue(item, `is_completed`, false)
                            )
                          }
                        >
                          <CheckCircleFilled
                            style={{
                              color: getValue(item, `is_completed`, false)
                                ? "#4cc793"
                                : "lightgray",
                              fontSize: "20px",
                            }}
                          />
                          {/* {getValue(item, `is_completed`, false) ? (
                            <CheckCircleSvgComponent
                              size={30}
                              color={"#4cc793"}
                            />
                          ) : (
                            <CircleSvgComponent color={"lightgray"} size={30} />
                          )} */}
                        </div>
                      }
                      text={
                        getValue(item, `is_completed`, false)
                          ? "Mark as Open"
                          : "Mark as Completed"
                      }
                      show
                      height={"40px"}
                      width={"150px"}
                    />
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-1 ms-2">
                    <div className="d-flex align-items-center">
                      {(getValue(item, `owner.first_name`, "") ||
                        getValue(item, `owner.last_name`, "")) && (
                        <AccountCircleSvgComponent
                          size={22}
                          color={"lightgray"}
                        />
                      )}
                      <p className="small_text__14 ms-1">
                        {getValue(item, `owner.first_name`, "")}{" "}
                        {getValue(item, `owner.last_name`, "")}
                      </p>
                    </div>
                    <p className="small_text__12  text-secondary">
                      {convertCurrentDateWithTime(
                        getValue(item, `created_at`, "")
                      )}
                    </p>
                  </div>
                  <div className="d-flex align-items-center mt-1 justify-content-between ms-2">
                    <div
                      className="d-flex align-items-center"
                      // style={getColorStyle(
                      //   getValue(item, `is_completed`, false)
                      //     ? "completed"
                      //     : "not-completed"
                      // )}
                    >
                      {getValue(item, `is_completed`, false) ? (
                        <Tag color="green">Completed</Tag>
                      ) : (
                        <Tag color="red">Not Completed</Tag>
                      )}

                      {/* <p
                        className={`small_text__12 ms-1 ${
                          getValue(item, `is_completed`, false)
                            ? "text-success"
                            : "text-danger"
                        }`}
                      >
                        {getValue(item, `is_completed`, false)
                          ? "Completed"
                          : "Not Completed"}
                      </p> */}
                    </div>
                    <p
                      className={`small_text__14 ${
                        getValue(item, `priority`, "") === "High"
                          ? "text-danger"
                          : getValue(item, `priority`, "") === "Medium"
                          ? "text-warning"
                          : "text-secondary"
                      }`}
                    >
                      {formatString(getValue(item, `priority`, ""))}
                    </p>
                  </div>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
}

export default TaskDetailLeftSection;
