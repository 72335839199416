import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const createSubPipeline = (payload: object, module: string) =>
  post(`${config.API_URL}/records/${module}`, payload);

export const deleteModuleCommon = (id: string, module: string) =>
  Delete(`${config.API_URL}/records/${module}/${id}`);

export const associationSubPipeline = (
  module: string,
  id: string,
  payload: object
) =>
  post(`${config.API_URL}/records/${module}/${id}/associations/add`, payload);

export const disAssociationSubPipeline = (
  module: string,
  id: string,
  payload: object
) =>
  post(
    `${config.API_URL}/records/${module}/${id}/associations/remove`,
    payload
  );

export const updateModuleCommon = (
  module: string,
  id: string,
  payload: object
) => patch(`${config.API_URL}/records/${module}/${id}`, payload);

export const checkModuleDuplicates = (module: string, queryRequest: string) =>
  get(`${config.API_URL}/records/${module}/check-duplicates?${queryRequest}`);

export const commonBulkUpdate = (module: string, payload: object) =>
  patch(`${config.API_URL}/records/${module}/bulk-update`, payload);

export const getDuplicatesData = (payload: object) =>
  post(`${config.API_URL}/public/records/contacts/custom/filter`, payload);
