import InputRuleForm from "@components/InputRuleForm/form";
import { getValue } from "@utils/lodash";
import { Button, Radio, Space } from "antd";
import React from "react";

const BroadcastVariableConfig = ({
  request,
  setRequest,
  handleModal,
  options,
  onChangeExtractType,
}: any) => {
  return (
    <div className="p-4">
      {getValue(request, `variableConfig.templateTags`, []).map(
        (item: any, index: any) => (
          <div key={index} className="d-flex align-items-center gap-2">
            <Radio.Group
              onChange={(e: any) => onChangeExtractType(e, index)}
              value={getValue(item, `extractType`, "")}
            >
              <Space direction="vertical">
                {options.map((item: any, i: any) => (
                  <Radio value={item.value} key={i}>
                    {item.label}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
            <InputRuleForm
              // label={`{{${getValue(item, `tag`, "")}}}`}
              inputType="TEXT"
              // value={getValue(item, `tag`, "")}
              value={
                getValue(item, `extractType`, "") === "manual"
                  ? getValue(item, `value`, "")
                  : getValue(item, `tag`, "")
              }
              name="name"
              placeholder={`${getValue(item, `actual_tag`, "")}`}
              onChange={(e: any) =>
                setRequest((prevRequest: any) => {
                  return {
                    ...prevRequest,
                    variableConfig: {
                      ...prevRequest.variableConfig,
                      templateTags: prevRequest.variableConfig.templateTags.map(
                        (button: any, i: number) => {
                          if (index !== i) return button;
                          return {
                            ...button,
                            [getValue(item, `extractType`, "") === "manual"
                              ? "value"
                              : "tag"]: e.target.value,
                          };
                        }
                      ),
                    },
                  };
                })
              }
              required
              className="mb-2"
            />
            <Button className="mt-3" onClick={() => handleModal(index)}>
              Pick Variable
            </Button>
          </div>
        )
      )}
    </div>
  );
};

export default BroadcastVariableConfig;
