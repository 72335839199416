import DragAndDropFileComponent from "@components/custom/FileUpload/file-drag-drop";
import { getValue } from "@utils/lodash";
import { Checkbox, Image } from "antd";
import { config } from "env";
import React from "react";

const BroadcastMediaEdit = ({ request, handleUploadDocuments }: any) => {
  return (
    <div className="p-4">
      <div className="d-flex gap-4 border rounded-3 p-4">
        <div className="w-50">
          <p className="form-label mt-0">Broadcast Media</p>
          <div>
            <Image
              src={`https://${config.URL}/${getValue(
                request,
                `variableConfig.headerLinkDetail.key`,
                ""
              )}`}
            />
          </div>
        </div>
        <div className="w-50">
          <p className="form-label mt-0">Upload (Image/video) to replace new</p>

          <DragAndDropFileComponent uploadFile={handleUploadDocuments} />

          {/* <Dragger className="broadcast-drag-drop">
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Support for a single or bulk upload. Strictly prohibited from
                  uploading company data or other banned files.
                </p>
              </Dragger> */}
        </div>
      </div>
      <Checkbox checked className="mt-2">
        Selected
      </Checkbox>
    </div>
  );
};

export default BroadcastMediaEdit;
