import React, { useState } from "react";
import { getValue } from "@utils/lodash";
import { formatString } from "@common/text-helpers";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ButtonComponent from "@components/Form/Button/Button";

function DataAdminstrationNavViews(props: any) {
  const { handleTab, urlParams } = props;
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [tabs] = useState([
    {
      value: "Exports",
      route: `/${getValue(
        params,
        `orgId`,
        ""
      )}/settings/data-administration-exports`,
    },
  ]);
  return (
    <ul
      className="d-flex align-items-center justify-content-between"
      style={{ borderBottom: "1px solid #efefef" }}
    >
      <div className="d-flex align-items-center justify-content-start">
        {tabs.map((linkObj: object, index: number) => {
          return (
            <li
              key={`pending-${index}`}
              className={` pt-3 px-2 ${
                getValue(location, `pathname`, "") ==
                getValue(linkObj, `route`, "")
                  ? "activebar__main-body-list_active"
                  : "activebar__main-body-list"
              }`}
              onClick={() => navigate(getValue(linkObj, `route`, ""))}
            >
              <a className={"activebar__main-body-link p-2 "}>
                {formatString(getValue(linkObj, `value`, ""))}
              </a>
            </li>
          );
        })}
      </div>
      <div className="">
        {getValue(location, `pathname`, "") ===
          `/${getValue(params, `orgId`, "")}/settings/emails/templates` && (
          <ButtonComponent
            buttonText={"New Template"}
            buttonType="primary"
            onClickHandler={props.handleModal}
          />
        )}
      </div>
    </ul>
  );
}

export default DataAdminstrationNavViews;
