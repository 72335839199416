import AccountCircleSvgComponent from "@assets/svg/account-circle";
import AudioSvgComponent from "@assets/svg/audio";
import CameraSvgComponent from "@assets/svg/camera";
import DocumentSvgComponent from "@assets/svg/document";
import ImageSvgComponent from "@assets/svg/image";
import MicSvgComponent from "@assets/svg/mic";
import VideoSvgComponent from "@assets/svg/video";
import { formatDatetoDay } from "@common/date-helpers";
import {
  replaceDefaultPlaceholders,
  replaceDefaultPlaceholdersBySingleLine,
} from "@common/markdown/markdown-helper";
import { concatString, formatString } from "@common/text-helpers";
import Loader from "@components/common/Loader/loading";
import ProfileCircle from "@components/common/ProfileCircle";
import { getValue } from "@utils/lodash";
import { Badge } from "antd";

function InboxContacts(props: any) {
  const {
    isLoading,
    conversations,
    selectedConversation,
    handleSelectConvesation,
    handleRemovePipelineInfo,
  } = props;

  return (
    <div className="chat-lits-wrapper">
      {isLoading ? (
        <Loader small />
      ) : getValue(conversations, `length`, 0) > 0 ? (
        conversations.map((item: object) => {
          return (
            <div
              className={`chat-list cursor-pointer ${
                selectedConversation === getValue(item, `id`, "")
                  ? "active"
                  : ""
              }`}
              onClick={() => {
                handleRemovePipelineInfo();
                handleSelectConvesation(item);
              }}
            >
              <div className="nonselected-profile-image">
                {getValue(item, `contact.first_name`, "") ||
                getValue(item, `contact.last_name`, "") ? (
                  <ProfileCircle
                    value={getValue(item, `contact.first_name`, "")}
                    value2={getValue(item, `contact.last_name`, "")}
                  />
                ) : getValue(item, `wa_profile_name`, "") &&
                  !parseInt(getValue(item, `wa_profile_name`, "")) ? (
                  <ProfileCircle
                    value={getValue(item, `wa_profile_name`, "")}
                  />
                ) : (
                  <AccountCircleSvgComponent color={"#dfe5e7"} size={40} />
                )}
              </div>

              <div className="w-100 d-flex flex-column gap-1">
                <div className="d-flex justify-content-between align-items-center">
                  {getValue(item, `contact.first_name`, "") ||
                  getValue(item, `contact.last_name`, "") ? (
                    <p className="small_text__16">
                      {concatString(
                        formatString(getValue(item, `contact.first_name`, "")),
                        18
                      )}{" "}
                      {concatString(
                        formatString(getValue(item, `contact.last_name`, "")),
                        8
                      )}
                    </p>
                  ) : (
                    <p className="small_text__16">
                      {concatString(
                        formatString(getValue(item, `wa_profile_name`, "")),
                        26
                      )}
                    </p>
                  )}

                  <span className="time-noti">
                    {formatDatetoDay(getValue(item, `updated_at`, ""))}
                  </span>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <p>
                    {getValue(item, `last_msg.text_body`, "") ? (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: replaceDefaultPlaceholdersBySingleLine(
                            concatString(
                              getValue(item, `last_msg.text_body`, ""),
                              30
                            )
                          ),
                        }}
                        className="desc_text small_text__12"
                      ></p>
                    ) : getValue(item, `last_msg.type`, "") === "image" ? (
                      <div className="d-flex align-items-center">
                        <CameraSvgComponent size={18} color={"gray"} />
                        <p className="small_text__12 ms-1">Image</p>
                      </div>
                    ) : getValue(item, `last_msg.type`, "") === "audio" ? (
                      <div className="d-flex align-items-center">
                        <MicSvgComponent size={18} color={"gray"} />
                        <p className="small_text__12 ms-1">Audio</p>
                      </div>
                    ) : getValue(item, `last_msg.type`, "") === "video" ? (
                      <div className="d-flex align-items-center">
                        <VideoSvgComponent size={18} color={"gray"} />
                        <p className="small_text__12 ms-1">Video</p>
                      </div>
                    ) : getValue(item, `last_msg.type`, "") === "document" ? (
                      <div className="d-flex align-items-center">
                        <DocumentSvgComponent size={18} color={"gray"} />
                        <p className="small_text__12 ms-1">Document</p>
                      </div>
                    ) : (
                      <p className="desc_text small_text__12 color_gray">
                        Hey there! I am using WhatsApp
                      </p>
                    )}
                  </p>
                  {getValue(item, `unreadCount`, 0) > 0 && (
                    <Badge
                      color="#25d366"
                      count={getValue(item, `unreadCount`, "")}
                    />
                  )}
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <p className="header_text__16 d-flex justify-content-center mt-5">
          No Conversations Found
        </p>
      )}
    </div>
  );
}

export default InboxContacts;
