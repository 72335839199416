import { convertDateTime } from "@common/date-helpers";
import { QueryRequestHelper } from "@common/query-request-helper";
import {
  formatString,
  removeNullOrUndefinedProperties,
} from "@common/text-helpers";
import ButtonComponent from "@components/Form/Button/Button";
import { intitalFilterViewState } from "@components/Pages/Pipeline/helpers/create-pipeline-helper";
import DragAndDropFileComponent from "@components/custom/FileUpload/file-drag-drop";
import { useStateContext } from "@context/profileProvider";
import { getAllContacts } from "@services/contacts.service";
import {
  generateBroadcastTemplate,
  getAvailableConfigList,
  getBroadcastConfigVaribles,
  getSpecificWhatsappBroadcast,
  getWhatsappBroadcastContacts,
  getWhatsappTemplate,
  manageWhatsappBroadcast,
  testBroadcast,
} from "@services/fb.service";
import { listAllPipelines } from "@services/pipeline.service";
import { commonCustomFilter } from "@services/smart-filter.service";
import { mediaUpload } from "@services/upload.service";
import { getValue } from "@utils/lodash";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Image, Modal, Radio, Steps, Table, Tag } from "antd";
import { toast } from "sonner";
import { handleDownloadOnClick } from "@common/downloadfile";
import BackSvgComponent from "@assets/svg/back-link";
import CommonInlineAddFilterView from "@components/Pages/Pipeline/AddFilterViews/inline-advance-filter";
import MainLayout from "@layouts/HomeLayout/NewLayout";
import SelectAudience from "./components/SelectAudience";
import TemplateSelector from "./components/TemplateSelector";
import ScheduleBroadcast from "./components/ScheduleBroadcast";
import { ExternalLinkIcon, Plus } from "lucide-react";
import EditBroadcastTemplate from "./components/EditBroadcastTemplate";
import { getAllLeads } from "@services/leads.service";
import { getAllAvailableTemplateTags } from "@services/email-config.service";
import AddWhatsappVariable from "../components/template-messages/add-variable-popup";
import TemplateCarouselModal from "../CreateTemplateMessage/components/template-carousel-modal";
import BroadcastTemplateCarouselModal from "../CreateTemplateMessage/components/broadcast-template-carousel-modal";
import { CheckCircleOutlined } from "@ant-design/icons";
import PhoneInputComponent from "@components/custom/Forms/PhoneInput";
import AddCircleSvgComponent from "@assets/svg/add-circle";
import RemoveCircleSvgComponent from "@assets/svg/remove-circle";
import { PhoneInput } from "react-international-phone";
import { removeDuplicatesById } from "@components/helpers/request-helper";
import _ from "lodash";

const CreateBroadcast = (props: any) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const params = useParams();
  const navigate = useNavigate();
  const { selectedModuleId } = useStateContext();
  const intialRequest: any = {
    id: "",
    waAccountId: "",
    name: "",
    body: "",
    templateId: "",
    timestamp: "",
    contacts: [],
    contactType: "contact",
    contactUploadId: "",
    variableConfig: {
      headerLink: "",
      buttons: [
        {
          type: "QUICK_REPLY",
          actionType: "url",
          text: "",
          value: "",
        },
      ],
      templateTags: [
        {
          pipelineId: "",
          data: [
            {
              type: "header",
              tag: "",
              sample: "",
              extractType: "manual",
              fieldId: "",
            },
          ],
        },
      ],
      tmpId: "",
    },
    isActive: true,
    countryCode: "",
  };

  /* -------------------------------------------------------------------------- */
  /*                                UseState Section                            */
  /* -------------------------------------------------------------------------- */

  const [request, setRequest] = useState(intialRequest);
  const [templateData, setTemplateDate] = useState([]);
  const [steps, setSteps] = useState(0);
  const [contactUploadStep, setContactUploadStep] = useState(false);
  const toggleContactUploadStep = () => {
    setContactUploadStep(!contactUploadStep);
  };
  const [timestamp, setTimestamp] = useState("now");
  const [scheduleTimestamp, setScheduleTimestamp] = useState<Date | undefined>(
    undefined
  );
  const [templateRequest, setTemplateRequest] = useState({});
  const reset = () => {
    setRequest(intialRequest);
    setTemplateRequest({});
  };
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  const broadcastId = getValue(params, "id", "");

  useEffect(() => {
    if (broadcastId) {
      getSpecificBrodcast(broadcastId);
      getTemplateList();
      associatedContacts(broadcastId);
    } else {
      getData();
      getTemplateList();
      getAllPipelinesList("contacts");
    }
    getConnectedWhatsappList();
  }, []);

  useEffect(() => {
    const container = document.querySelector(".manageFieldRight");
    if (container) {
      container.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [steps]);

  /* -------------------------------------------------------------------------- */
  /*                                   API Section                              */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const getData = async () => {
    try {
      setIsLoading(true);
      let resp = await getSpecificWhatsappBroadcast(getValue(params, `id`, ""));
      if (resp) {
        setList(getValue(resp, `data.broadcasts`, []));
        // setTotalCount(getValue(resp, `data.total`, 0));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const [isLoading1, setIsLoading1] = useState(false);
  const [templateList, setTemplateList] = useState([]);
  const getTemplateList = async () => {
    try {
      setIsLoading1(true);
      let payload = {
        status: "APPROVED",
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getWhatsappTemplate(queryRequest);
      if (resp) {
        setTemplateList(
          getValue(resp, `data.templates`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `id`, ""),
            label: getValue(item, `name`, ""),
          }))
        );
        setTemplateDate(getValue(resp, `data.templates`, []));
        // setRequest({
        //   ...request,
        //   templateId: getValue(resp, `data.templates[${0}].id`, ""),
        //   name: getValue(resp, `data.templates[${0}].name`, ""),
        //   body: getValue(resp, `data.templates[${0}].body`, ""),
        // });
        setIsLoading1(false);
      } else {
        setIsLoading1(false);
      }
    } catch (error) {
      setIsLoading1(false);
    }
  };

  const handleChangePipeline = (id: string) => {
    setPipelineId(id);
    getAllContact(page_no1, limit1, selectedAdvancedFiter, id);
  };

  /* -------------------------------------------------------------------------- */
  /*                           Contacts/Leads Section                           */
  /* -------------------------------------------------------------------------- */

  const [pipelineId, setPipelineId] = useState("");
  const [moduleId, setModuleId] = useState("");
  const [allPipelines, setAllPipelines] = useState([]);

  const getAllPipelinesList = async (module: string) => {
    let moduleId = await selectedModuleId(module);
    setModuleId(moduleId);
    if (moduleId) {
      try {
        let allPipelines = await listAllPipelines(moduleId, "");
        let list = getValue(allPipelines, `data`, []).map((item: object) => ({
          ...item,
          value: getValue(item, `id`, ""),
          label: getValue(item, `label`, ""),
        }));
        setAllPipelines(list);
        setPipelineId(getValue(allPipelines, `data.[${0}].id`, ""));
        if (module === "contacts") {
          getAllContact(
            page_no1,
            limit1,
            selectedAdvancedFiter,
            getValue(allPipelines, `data.[${0}].id`, "")
          );
        } else {
          getAllLead(
            page_no1,
            limit1,
            selectedAdvancedFiter,
            getValue(allPipelines, `data.[${0}].id`, "")
          );
        }
      } catch (error) {}
    } else {
      setAllList([]);
      setAllPipelines([]);
      setTotalCount1(0);
      toast.error("Leads not enabled");
    }
  };

  const [allList, setAllList] = useState([]);
  const [totalCount1, setTotalCount1] = useState(0);
  const [moduleTab, setModuleTab] = useState(1);
  const [modulePipeline, setModulePipeline] = useState("");
  const handleModule = (key: any) => {
    setModuleTab(key);
    if (key == 1) {
      setRequest((prevState: any) => ({
        ...prevState,
        contactType: "contact",
      }));
      getAllPipelinesList("contacts");
    } else {
      setRequest((prevState: any) => ({
        ...prevState,
        contactType: "lead",
      }));
      getAllPipelinesList("leads");
    }
  };
  const getAllContact = async (
    page: any,
    limit: any,
    filter: any,
    pipeline_id: string
  ) => {
    try {
      let payload = {
        page_no: page,
        page_size: limit,
        pipeline_id: pipeline_id,
        module_id: await selectedModuleId("contacts"),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp;
      if (getValue(filter, `length`, 0) > 0) {
        let obj = {
          has_advance_filter: true,
          has_group_by: false,
          filter_groups: filter,
        };
        resp = await commonCustomFilter("contacts", queryRequest, obj);
      } else {
        resp = await getAllContacts(queryRequest);
      }
      if (resp) {
        setAllList(getValue(resp, `data.contacts`, []));
        setTotalCount1(getValue(resp, `data.pagination.total`, 0));
        // setSelectedIds([]);
      }
    } catch (error) {}
  };

  const getAllLead = async (
    page: any,
    limit: any,
    filter: any,
    pipeline_id: string
  ) => {
    try {
      let payload = {
        page_no: page,
        page_size: limit,
        pipeline_id: pipeline_id,
        module_id: await selectedModuleId("leads"),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp;
      if (getValue(filter, `length`, 0) > 0) {
        let obj = {
          has_advance_filter: true,
          has_group_by: false,
          filter_groups: filter,
        };
        resp = await commonCustomFilter("leads", queryRequest, obj);
      } else {
        resp = await getAllLeads(queryRequest);
      }
      if (resp) {
        setAllList(getValue(resp, `data.leads`, []));
        setTotalCount1(getValue(resp, `data.pagination.total`, 0));
        // setSelectedIds([]);
      }
    } catch (error) {}
  };

  const handleSelectPipeline = (id: string) => {
    getAllLead(1, limit1, selectedAdvancedFiter, id);
  };

  const [page_no1, setPage_no1] = useState(1);
  const [limit1, setLimit1] = useState(10);
  const handleChangePagination1 = (page: any, limit1: any) => {
    setPage_no1(page);
    setLimit1(limit1);
    if (getValue(request, `contactType`, "") === "contact") {
      getAllContact(page, limit1, selectedAdvancedFiter, pipelineId);
    } else {
      getAllLead(page, limit1, selectedAdvancedFiter, pipelineId);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                    Contacts/Leads Variables section                        */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (pipelineId && moduleId) {
      tagsByPipelineID(pipelineId, moduleId);
    }
  }, [pipelineId, moduleId]);
  const [allTags, setAllTags] = useState([]);
  const tagsByPipelineID = async (id: string, moduleId: string) => {
    if (moduleId) {
      try {
        let payload = {
          pipelineId: id,
        };
        let queryRequest = QueryRequestHelper(payload);
        let resp = await getAllAvailableTemplateTags(moduleId, queryRequest);
        if (resp) {
          let list = getValue(resp, `data`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `name`, ""),
            // placeHolderOld: getValue(item, `placeHolder`, ""),
            // placeHolder: `[[${getValue(item, `name`, "")}]]`,
          }));
          const uniqueData: any = _.uniqBy(list, "fieldId");
          setAllTags(uniqueData);
        }
      } catch (error) {}
    } else {
      toast.error("Module not enabled");
    }
  };

  const handleAddTags = (item: any) => {
    setRequest((prevRequest: any) => {
      return {
        ...prevRequest,
        variableConfig: {
          ...prevRequest.variableConfig,
          templateTags: prevRequest.variableConfig.templateTags.map(
            (button: any, i: number) => {
              if (tagIndex !== i) return button;
              return {
                ...button,
                tag: getValue(item, `name`, ""),
              };
            }
          ),
        },
      };
    });
  };

  const [allConnectionList, setAllConnectionList] = useState([]);
  const getConnectedWhatsappList = async () => {
    try {
      let payload = {};
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAvailableConfigList(queryRequest);
      if (resp) {
        if (getValue(resp, `data.length`, 0) > 0) {
          setAllConnectionList(
            getValue(resp, `data`, []).map((item: object) => ({
              ...item,
              label:
                getValue(item, `verifiedName`, "") +
                " ( " +
                getValue(item, `displayPhoneNumber`, "") +
                " )",
              value: getValue(item, `id`, ""),
            }))
          );
        }
      }
    } catch (error) {}
  };
  /* -------------------------------------------------------------------------- */
  /*                                   Modal Section                            */
  /* -------------------------------------------------------------------------- */

  const [isOpen, setIsOpen] = useState(false);
  const [tagIndex, setTagIndex] = useState<any>(null);
  const handleTag = (index: number) => {
    setTagIndex(index);
    handleModal();
  };
  const handleModal = () => {
    setIsOpen(!isOpen);
  };

  const [isOpen1, setIsOpen1] = useState(false);
  const handleModal1 = () => {
    setIsOpen1(!isOpen1);
  };

  /* -------------------------------------------------------------------------- */
  /*                               OnChange Section                             */
  /* -------------------------------------------------------------------------- */

  const handleTemplateRequest = (id: string, name: string, body: string) => {
    setRequest({
      ...request,
      templateId: id,
      name: name,
      body: body,
    });
    let info: any = templateList.find(
      (item: object) => getValue(item, `id`, "") === id
    );
    handlePreview(info);
  };
  /* -------------------------------------------------------------------------- */
  /*                                    Checkbox Section                        */
  /* -------------------------------------------------------------------------- */
  const [selectedIds, setSelectedIds] = useState<any>([]);
  const handleChangeCheckbox = (id: any) => {
    let checkIfExist =
      getValue(selectedIds, `length`, 0) > 0
        ? selectedIds.filter((item: any) => item === id)
        : [];
    if (getValue(checkIfExist, `length`, 0) > 0) {
      let filtered =
        getValue(selectedIds, `length`, 0) > 0
          ? selectedIds.filter((item: any) => item !== id)
          : [];
      setSelectedIds(filtered);
    } else {
      selectedIds.push(id);
      setSelectedIds([...selectedIds]);
    }
  };

  const handleChangeAllCheckbox = (value: any) => {
    if (value === "all_checked") {
      setSelectedIds([]);
    } else {
      let IDs = allList.map((item: any) => item.id);
      setSelectedIds(IDs);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                   Submit Section                           */
  /* -------------------------------------------------------------------------- */

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    if (steps === 0) {
      if (!downloadFile) {
        // handleGenerateDownloadURL();
      }
      // getConfigVar();
      setSteps(steps + 1);
    } else if (steps < 3) {
      setSteps(steps + 1);
    } else {
      try {
        setSubmitLoading(true);
        let obj = {
          ...request,
          userTagMapping: removeNullOrUndefinedProperties({
            ...getValue(request, `variableConfig`, ""),
            tags: getValue(request, `variableConfig.templateTags`, {}).map(
              (item: object) => ({
                id: getValue(item, `id`, ""),
                contextId: !getValue(request, `contactUploadId`, "")
                  ? getContextTagId(getValue(item, `tag`, ""))
                  : getContextId(getValue(item, `tag`, "")),
                extractType: !getValue(request, `contactUploadId`, "")
                  ? "field"
                  : getValue(item, `extractType`, ""),
                sample: getValue(item, `sample`, ""),
                tag: getValue(item, `tag`, ""),
                type: getValue(item, `type`, ""),
                value: getValue(item, `value`, ""),
              })
            ),
            // pipelineId: pipelineId,
          }),
          configTmpId: getValue(request, `variableConfig.tmpId`, ""),
          timestamp: scheduleTimestamp
            ? convertDateTime(scheduleTimestamp)
            : convertDateTime(new Date()),
          contacts: selectedIds,
          isActive: true,
          waAccountId: getValue(request, `waAccountId`, ""),
        };
        delete obj["variableConfig"];
        delete obj["userTagMapping"]["templateTags"];
        delete obj["userTagMapping"]["tmpId"];

        let resp = await manageWhatsappBroadcast(
          removeNullOrUndefinedProperties(obj)
        );
        if (resp) {
          toast.success("Submitted successfully");
          getData();
          reset();
          setTemplateRequest({});
          setUploadedFileInfo({});
          setDownloadFile("");
          navigate(`/${getValue(params, "orgId", "")}/broadcast`);
        }
      } catch (error) {
        console.error("Submission failed:", error);
      } finally {
        setSubmitLoading(false);
      }
    }
  };

  const getContextId = (tag: string) => {
    let info: any = uploadedFileFieldOption.find(
      (ite: object) => getValue(ite, `column`, "") === tag
    );
    return getValue(info, `id`, "");
  };

  const getContextTagId = (name: string) => {
    let info: any = contextTagList.find(
      (ite: object) => getValue(ite, `name`, "") === name
    );
    return getValue(info, `fieldId`, "");
  };

  const getSpecificBrodcast = async (id: string) => {
    try {
      let resp = await getSpecificWhatsappBroadcast(id);
      if (resp) {
        handlePreview(getValue(resp, `data.template`, {}));
        setRequest({
          ...request,
          id: getValue(resp, `data.id`, ""),
          name: getValue(resp, `data.name`, ""),
          templateId: getValue(resp, `data.template_id`, ""),
          timestamp: getValue(resp, `data.timestamp`, ""),
          isActive: getValue(resp, `data.is_active`, false),
          waAccountId: getValue(resp, `data.wa_account_id`, ""),
        });
      }
    } catch (error) {}
  };

  const [editId, setEditId] = useState("");
  const handlePreview = (findInfo: any) => {
    setEditId("");
    let buttons =
      getValue(findInfo, `buttons.length`, 0) > 0
        ? getValue(findInfo, `buttons`, []).map((item: object) => ({
            ...item,
            type: getValue(item, `action_type`, ""),
            action_type: getValue(item, `type`, ""),
          }))
        : [];
    setTemplateRequest({
      ...templateRequest,
      header: {
        type: getValue(findInfo, `header_type`, ""),
        text: getValue(findInfo, `text`, ""),
        link: getValue(findInfo, `header_file.key`, ""),
      },
      body: getValue(findInfo, `body`, ""),
      footer: getValue(findInfo, `footer`, ""),
      buttons: buttons,
    });
    // handleModal();
  };

  const [associatedContactList, setAssociatedContactsList] = useState([]);
  const associatedContacts = async (id: string) => {
    try {
      let resp = await getWhatsappBroadcastContacts(id);
      if (resp) {
        setAssociatedContactsList(getValue(resp, `data.broadcasts`, []));
      }
    } catch (error) {}
  };
  /* -------------------------------------------------------------------------- */
  /*                       Create Filterview Section                            */
  /* -------------------------------------------------------------------------- */
  const [createViewStep, setCreateViewStep] = useState(1);
  const [isOpenFilterView, setIsOpenFilterView] = useState(false);
  const [filterRequest, setFilterRequest] = useState(intitalFilterViewState);
  const [filterViewId, setFilterId] = useState("");
  const handleOpenFilterView = () => {
    setIsOpenFilterView(!isOpenFilterView);
    setFilterRequest(intitalFilterViewState);
    setFilterId("");
    setCreateViewStep(1);
  };
  const [selectedAdvancedFiter, setSelectedAdvancedFiter] = useState([]);
  const handleSubmitFilter = (filter: any) => {
    setSelectedAdvancedFiter(filter);
    setIsOpenFilterView(!isOpenFilterView);
    getAllContact(page_no1, limit1, filter, pipelineId);
  };
  const handleRemoveSmartFilter = () => {
    setSelectedAdvancedFiter([]);
    getAllContact(page_no1, limit1, [], pipelineId);
  };

  /* -------------------------------------------------------------------------- */
  /*                          Download URL Section                              */
  /* -------------------------------------------------------------------------- */
  const [downloadFile, setDownloadFile] = useState("");
  const handleGenerateDownloadURL = async (status: boolean) => {
    try {
      let resp = await generateBroadcastTemplate({
        templateId: getValue(request, `templateId`, ""),
        includeCountryCode: status,
      });
      if (resp) {
        setDownloadFile(getValue(resp, `data.url`, ""));
        handleDownloadOnClick(getValue(resp, `data.url`, ""), "template");
        // handleModal1();
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                            Upload Section                                  */
  /* -------------------------------------------------------------------------- */

  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadedFileInfo, setUploadedFileInfo] = useState({});
  const handleUploadDocuments = async (e: any) => {
    Array.from(e).forEach(async (item: any) => {
      let formData = new FormData();
      formData.append("file", item);
      formData.append("filename", item.name);
      formData.append("is_public", "true");
      formData.append("collection", "public-images");
      try {
        setUploadLoading(true);
        let resp = await mediaUpload(formData);
        if (resp) {
          setUploadedFileInfo(getValue(resp, `data`, {}));
          setRequest((prevState: any) => ({
            ...prevState,
            contactUploadId: getValue(resp, `data.id`, ""),
          }));
          e = null;
          setUploadLoading(false);
        } else {
          e = null;
          setUploadLoading(false);
        }
      } catch (error) {
        e = null;
        setUploadLoading(false);
      }
    });
  };

  const setImportTedFile = (value: string) => {
    if (!value) {
      setUploadedFileInfo({});
    }
  };

  const [selectionType, setSelectionType] = useState<any>("checkbox");
  const rowSelection = {
    selectedRowKeys: selectedIds,
    onChange: (selectedRowKeys: any) => {
      setSelectedIds(selectedRowKeys);
    },
    onSelectAll: (
      selected: boolean,
      selectedRows: any[],
      changeRows: any[]
    ) => {
      if (selected) {
        setSelectedIds(allList.map((contact: any) => contact.id));
      } else {
        setSelectedIds([]);
      }
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  const handleBack = () => {
    setSteps(steps - 1);
  };

  const [uploadedFileFieldOption, setUploadedFileFieldOption] = useState([]);
  const [contextTagList, setContextTagList] = useState([]);

  const getConfigVar = async () => {
    try {
      let payload = {
        contextId: getValue(selectedIds, `[${0}]`, ""),
        isContactContext: !getValue(request, `contactUploadId`, "")
          ? getValue(request, `contactType`, "") === "contact"
            ? true
            : false
          : false,
        fileId: getValue(request, `contactUploadId`, ""),
        templateId: getValue(request, `templateId`, ""),
      };
      let resp = await getBroadcastConfigVaribles(
        getValue(request, `templateId`, ""),
        removeNullOrUndefinedProperties(payload)
      );
      if (resp) {
        let info = {
          ...getValue(resp, `data`, ""),
          templateTags: getValue(resp, `data.templateTags`, []).map(
            (item: object) => ({
              ...item,
              actual_tag: getValue(item, `tag`, ""),
            })
          ),
        };
        setRequest((prevState: any) => ({
          ...prevState,
          variableConfig: info,
        }));

        let list =
          getValue(resp, `data.fileTags.length`, 0) > 0
            ? getValue(resp, `data.fileTags`, []).map((item: object) => ({
                ...item,
                label: formatString(getValue(item, `column`, "")),
                value: getValue(item, `column`, ""),
              }))
            : [];

        let contextList =
          getValue(resp, `data.contextTags.length`, 0) > 0
            ? getValue(resp, `data.contextTags`, []).map((item: object) => ({
                ...item,
              }))
            : [];
        setUploadedFileFieldOption(list);
        setContextTagList(contextList);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (
      getValue(request, `contactUploadId`, "") ||
      getValue(selectedIds, `length`, 0) > 0
    ) {
      getConfigVar();
    }
  }, [
    getValue(request, `contactUploadId`, ""),
    getValue(selectedIds, `length`, 0) > 0,
  ]);

  const handleUploadMediaDocuments = async (e: any) => {
    Array.from(e).forEach(async (item: any) => {
      let formData = new FormData();
      formData.append("file", item);
      formData.append("filename", item.name);
      formData.append("is_public", "true");
      formData.append("collection", "public-images");
      try {
        setUploadLoading(true);
        let resp = await mediaUpload(formData);
        if (resp) {
          setRequest((prevState: any) => ({
            ...prevState,
            variableConfig: {
              ...prevState.variableConfig,
              headerLinkDetail: getValue(resp, `data`, ""),
            },
          }));
          e = null;
          setUploadLoading(false);
        } else {
          e = null;
          setUploadLoading(false);
        }
      } catch (error) {
        e = null;
        setUploadLoading(false);
      }
    });
  };

  /* -------------------------------------------------------------------------- */
  /*                          Carousel Section                                  */
  /* -------------------------------------------------------------------------- */

  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const handleCancel = (index: any) => {
    if (!getValue(request, `carousel.${index}.body`, "")) {
      handleRemoveCarousel(index);
    }
    setIsModalOpen(false);
  };

  const handleRemoveCarousel = (index: number) => {
    let filtered = getValue(request, `carousel`, []).filter(
      (_item: object, i: number) => i !== index
    );
    setRequest({
      ...request,
      carousel: filtered,
    });
  };

  const [selectedCarousel, setSelectedCarousel] = useState(0);
  const showModal = (index: any) => {
    setSelectedCarousel(index);
    toggleModal();
  };

  const handleUploadCarouselDocuments = async (e: any) => {
    Array.from(e).forEach(async (item: any) => {
      let formData = new FormData();
      formData.append("file", item);
      formData.append("filename", item.name);
      formData.append("is_public", "true");
      formData.append("collection", "public-images");
      try {
        setUploadLoading(true);
        let resp = await mediaUpload(formData);
        if (resp) {
          setRequest((prevRequest: any) => {
            return {
              ...prevRequest,
              variableConfig: {
                ...prevRequest.variableConfig,
                carousels: prevRequest.variableConfig.carousels.map(
                  (carousel: any, i: number) => {
                    if (selectedCarousel !== i) return carousel;
                    return {
                      ...carousel,
                      headerLinkDetail: getValue(resp, `data`, {}),
                    };
                  }
                ),
              },
            };
          });
          e = null;
          setUploadLoading(false);
        } else {
          e = null;
          setUploadLoading(false);
        }
      } catch (error) {
        e = null;
        setUploadLoading(false);
      }
    });
  };
  /* ------------------------------------------------------------- */
  /*                      Broadcast Test Section                   */
  /* ------------------------------------------------------------- */
  const [isOpen2, setIsOpen2] = useState(false);
  const handleModal2 = () => {
    setIsOpen2(!isOpen2);
  };
  const [phoneNumbers, setPhoneNumbers] = useState<any>([{ value: "" }]);
  const handleAddPhoneNumbers = () => {
    phoneNumbers.push({ value: "" });
    setPhoneNumbers([...phoneNumbers]);
  };

  const handleRemovePhoneNumber = (index: number) => {
    let filtered = phoneNumbers.filter(
      (_item: object, i: number) => i !== index
    );
    setPhoneNumbers(filtered);
  };

  const handleTestBroadcast = async () => {
    let obj = {
      ...request,
      userTagMapping: removeNullOrUndefinedProperties({
        ...getValue(request, `variableConfig`, ""),
        tags: getValue(request, `variableConfig.templateTags`, {}).map(
          (item: object) => ({
            id: getValue(item, `id`, ""),
            contextId: !getValue(request, `contactUploadId`, "")
              ? getContextTagId(getValue(item, `tag`, ""))
              : getContextId(getValue(item, `tag`, "")),
            extractType: !getValue(request, `contactUploadId`, "")
              ? "field"
              : getValue(item, `extractType`, ""),
            sample: getValue(item, `sample`, ""),
            tag: getValue(item, `tag`, ""),
            type: getValue(item, `type`, ""),
            value: getValue(item, `value`, ""),
          })
        ),
        // pipelineId: pipelineId,
      }),
      configTmpId: getValue(request, `variableConfig.tmpId`, ""),
      timestamp: scheduleTimestamp
        ? convertDateTime(scheduleTimestamp)
        : convertDateTime(new Date()),
      numbers: phoneNumbers.map((item: object) => getValue(item, `value`, "")),
      isActive: true,
      waAccountId: getValue(request, `waAccountId`, ""),
    };
    delete obj["variableConfig"];
    delete obj["userTagMapping"]["templateTags"];
    delete obj["userTagMapping"]["tmpId"];
    try {
      let resp = await testBroadcast(removeNullOrUndefinedProperties(obj));
      if (resp) {
        toast.success("Broacast sent successfully");
        setPhoneNumbers([]);
        handleModal2();
      }
    } catch (error) {}
  };
  return (
    <MainLayout {...props}>
      <div className="px-3 py-2">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <div
              onClick={() =>
                navigate(`/${getValue(params, `orgId`, "")}/broadcast`)
              }
            >
              <BackSvgComponent />
            </div>
            <p className="small_text__16 ms-3">New broadcast message</p>
          </div>
          {!broadcastId &&
            getValue(props, `permissions`, []).includes("create") && (
              <div className="d-flex justify-content-end gap-2 align-items-center">
                {steps === 0 && templateData.length !== 0 && (
                  <a
                    target="_blank"
                    href={`/${getValue(
                      params,
                      "orgId",
                      ""
                    )}/broadcast?activeTab=2&page_no=1`}
                    className="pe-3 d-flex align-items-center gap-2"
                  >
                    New Template <ExternalLinkIcon size={18} />
                  </a>
                )}
                <Button
                  size="large"
                  onClick={handleBack}
                  disabled={steps === 0}
                >
                  Back
                </Button>
                <Button
                  type="primary"
                  size="large"
                  onClick={handleSubmit}
                  loading={submitLoading}
                  // disabled={
                  //   templateData.length === 0 ||
                  //   (steps === 1 &&
                  //     (request.waAccountId === "" ||
                  //       request.name === "" ||
                  //       selectedIds.length === 0))
                  // }
                  disabled={
                    (steps === 0 &&
                      getValue(request, "templateId", "") === "") ||
                    (steps === 1 &&
                      getValue(request, "contactUploadId", "") === "" &&
                      selectedIds.length === 0) ||
                    (steps === 3 &&
                      getValue(request, "waAccountId", "") === "") ||
                    getValue(request, "name", "") === ""
                  }
                >
                  {steps < 3 ? "Next" : "Send Broadcast"}
                </Button>
                {steps == 3 && (
                  <Button onClick={handleModal2}>Test Broadcast</Button>
                )}
              </div>
            )}
        </div>
      </div>
      <div className="border-bottom"></div>
      <div className="template-wrappr">
        <div className="d-flex w-100 justify-content-center">
          <div className="w-20 d-flex justify-content-center">
            <Steps
              direction="vertical"
              className=" mx-auto mt-4 px-5 broadcast-stepper-wrapper"
              current={steps}
              items={[
                {
                  title: "Select Template",
                },
                {
                  title: "Select Audience",
                },
                {
                  title: "Map Template",
                },
                {
                  title: "Schedule Broadcast",
                },
              ]}
            />
          </div>

          <div className="manageFieldRight w-80 overflow-y-auto p-4">
            <div className="template-editor-wrapper">
              <div className="">
                {steps === 0 && (
                  <TemplateSelector
                    isLoading1={isLoading1}
                    request={request}
                    handleTemplateRequest={handleTemplateRequest}
                    templateData={templateData}
                  />
                )}
                {steps === 1 && (
                  <SelectAudience
                    request={request}
                    allPipelines={allPipelines}
                    handleChangePipeline={handleChangePipeline}
                    allList={allList}
                    selectionType={selectionType}
                    rowSelection={rowSelection}
                    page_no1={page_no1}
                    limit1={limit1}
                    totalCount1={totalCount1}
                    handleChangePagination1={handleChangePagination1}
                    selectedAdvancedFiter={selectedAdvancedFiter}
                    handleOpenFilterView={handleOpenFilterView}
                    handleRemoveSmartFilter={handleRemoveSmartFilter}
                    allConnectionList={allConnectionList}
                    setRequest={setRequest}
                    //contact upload
                    contactUploadStep={contactUploadStep}
                    toggleContactUploadStep={toggleContactUploadStep}
                    downloadFile={downloadFile}
                    handleUploadDocuments={handleUploadDocuments}
                    uploadedFileInfo={uploadedFileInfo}
                    uploadLoading={uploadLoading}
                    handleModule={handleModule}
                    pipelineId={pipelineId}
                    setPipelineId={setPipelineId}
                    handleSelectPipeline={handleSelectPipeline}
                    handleGenerateDownloadURL={handleGenerateDownloadURL}
                  />
                )}
                {steps === 2 && (
                  <EditBroadcastTemplate
                    request={request}
                    setRequest={setRequest}
                    handleModal={handleTag}
                    handleUploadDocuments={handleUploadMediaDocuments}
                    handleUploadCarouselDocuments={
                      handleUploadCarouselDocuments
                    }
                    showModal={showModal}
                    uploadedFileFieldOption={uploadedFileFieldOption}
                  />
                )}
                {steps === 3 && (
                  <ScheduleBroadcast
                    timestamp={timestamp}
                    setTimestamp={setTimestamp}
                    scheduleTimestamp={scheduleTimestamp}
                    setScheduleTimestamp={setScheduleTimestamp}
                    allConnectionList={allConnectionList}
                    setRequest={setRequest}
                    request={request}
                  />
                )}
              </div>
            </div>
          </div>
          {/* <div className="">
            <WhatsappIconViewPage request={templateRequest} />
          </div> */}
        </div>
      </div>
      <Modal
        open={isOpen1}
        onCancel={handleModal1}
        onOk={handleSubmit}
        title="Import Contacts"
        footer={[
          <Button
            key="cancel"
            onClick={handleModal1}
            className="cancel__button"
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            className="submit__button"
          >
            Submit
          </Button>,
        ]}
      >
        <div className="border-bottom"></div>
        <div className="d-flex justify-content-end mt-3">
          <div>
            <ButtonComponent
              buttonType={"primary"}
              buttonText={"Download Template"}
              onClickHandler={() =>
                handleDownloadOnClick(downloadFile, "template")
              }
            />
          </div>
        </div>
        <DragAndDropFileComponent
          uploadFile={handleUploadDocuments}
          name="file"
          classes="bulk-upload_drag-n-drop-wrapper position-absolute"
          fileName={getValue(uploadedFileInfo, `filename`, "")}
          setImportTedFile={setImportTedFile}
          isLoading={uploadLoading}
        />
      </Modal>

      {/* ------------- Test Broadcast ---------- */}
      <Modal
        open={isOpen2}
        onCancel={handleModal2}
        onOk={handleSubmit}
        title="Test Broadcast"
        footer={[
          <Button
            key="cancel"
            onClick={handleModal2}
            className="cancel__button"
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleTestBroadcast}
            className="submit__button"
          >
            Send
          </Button>,
        ]}
      >
        <div className="border-bottom"></div>
        <div className=" mt-3">
          <p className="mt-2 mb-2">Phone Numbers</p>
          {getValue(phoneNumbers, `length`, 0) > 0 &&
            phoneNumbers.map((item: object, index: number) => {
              return (
                <div key={index} className="d-flex align-items-center">
                  <PhoneInput
                    defaultCountry="in"
                    value={getValue(item, "value", "")}
                    onChange={(e) => {
                      const newValue = e;
                      setPhoneNumbers((prevPhoneNumbers: any) => {
                        return prevPhoneNumbers.map(
                          (phoneNumber: any, ind: number) => {
                            if (index !== ind) return phoneNumber;
                            return {
                              ...phoneNumber,
                              value: newValue,
                            };
                          }
                        );
                      });
                    }}
                    placeholder="Enter phone number (+91...)"
                  />
                  {/* <PhoneInputComponent
                    placeholder="Enter phone number (+91...)"
                    value={getValue(item, "value", "")}
                    // label="Phone Number"
                    onChange={(e: any) => {
                      setPhoneNumbers((object: any, ind: number) => {
                        return object.map((x: any, index: number) => {
                          console.log(e)
                          if (index !== ind) return x;
                          return {
                            ...x,
                            value: e,
                          };
                        });
                      });
                    }}
                  /> */}
                  <div
                    onClick={() => handleRemovePhoneNumber(index)}
                    className="ms-2 cursor-pointer"
                  >
                    <RemoveCircleSvgComponent />
                  </div>
                </div>
              );
            })}
        </div>
        <div
          className="d-flex align-items-center cursor-pointer mt-3"
          onClick={handleAddPhoneNumbers}
        >
          <AddCircleSvgComponent />
          <p className="color_success">Add Phone Number</p>
        </div>
      </Modal>
      {isOpenFilterView && (
        <CommonInlineAddFilterView
          isOpenFilterView={isOpenFilterView}
          module={"contacts"}
          handleOpenFilterView={handleOpenFilterView}
          pipeline_id={pipelineId}
          handleSubmit={handleSubmitFilter}
          selectedAdvancedFiter={selectedAdvancedFiter}
        />
      )}

      <AddWhatsappVariable
        isOpen={isOpen}
        handleModal={handleModal}
        handleAddTags={handleAddTags}
        allTags={allTags}
        tagsByPipelineID={tagsByPipelineID}
        allPipelinesList={allPipelines}
        pipelineId={pipelineId}
      />

      <BroadcastTemplateCarouselModal
        isModalOpen={isModalOpen}
        handleOk={toggleModal}
        handleCancel={handleCancel}
        request={request}
        setRequest={setRequest}
        activeCarouselTab={selectedCarousel}
        handleUploadCarouselDocuments={handleUploadCarouselDocuments}
        // carouselFileLists={carouselFileLists}
        // handleCarouseFileChange={handleCarouseFileChange}
        // handleFileRemove={handleFileRemove}
        // simpleValidator={simpleValidator}
      />
    </MainLayout>
  );
};

export default CreateBroadcast;
