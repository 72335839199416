import PhoneInputComponent from "@components/custom/Forms/PhoneInput";
import InputRuleForm from "@components/InputRuleForm/form";
import { getValue } from "@utils/lodash";
import React from "react";

const BroadcastButtonsEdit = ({ request, setRequest }: any) => {
  return (
    <div className="p-4 d-grid gap-3">
      {getValue(request, `variableConfig.buttons`, []).map(
        (item: any, index: any) => {
          const buttonType = getValue(item, "type", "");
          const actionType = getValue(item, "actionType", "");

          return (
            <div key={index}>
              {buttonType === "QUICK_REPLY" ? (
                <InputRuleForm
                  inputType="TEXT"
                  name="text"
                  value={getValue(item, "value", "")}
                  placeholder="e.g., Yes"
                  maxLength={20}
                  showCount
                  onChange={(e: any) => {
                    const { name, value } = e.target;
                    setRequest((prevRequest: any) => {
                      return {
                        ...prevRequest,
                        variableConfig: {
                          ...prevRequest.variableConfig,
                          buttons: prevRequest.variableConfig.buttons.map(
                            (button: any, i: number) => {
                              if (index !== i) return button;
                              return {
                                ...button,
                                value: value,
                              };
                            }
                          ),
                        },
                      };
                    });
                  }}
                />
              ) : (
                <div className="flex-wrap d-flex gap-2">
                  <div className="w-45">
                    <InputRuleForm
                      inputType="TEXT"
                      name="text"
                      value={getValue(item, "text", "")}
                      placeholder={
                        actionType === "url"
                          ? "e.g., Visit website"
                          : "e.g., Call us"
                      }
                      onChange={(e: any) => {
                        const { name, value } = e.target;
                        setRequest((prevRequest: any) => {
                          return {
                            ...prevRequest,
                            variableConfig: {
                              ...prevRequest.variableConfig,
                              buttons: prevRequest.variableConfig.buttons.map(
                                (button: any, i: number) => {
                                  if (index !== i) return button;
                                  return {
                                    ...button,
                                    text: value,
                                  };
                                }
                              ),
                            },
                          };
                        });
                      }}
                      label="Title"
                      maxLength={20}
                    />
                  </div>
                  <div className="w-45">
                    {actionType === "call" ? (
                      <PhoneInputComponent
                        placeholder="Enter phone number (+91...)"
                        value={getValue(item, "value", "")}
                        label="Phone Number"
                        onChange={(e: any) => {
                          setRequest((prevRequest: any) => {
                            return {
                              ...prevRequest,
                              variableConfig: {
                                ...prevRequest.variableConfig,
                                buttons: prevRequest.variableConfig.buttons.map(
                                  (button: any, i: number) => {
                                    if (index !== i) return button;
                                    return {
                                      ...button,
                                      value: e,
                                    };
                                  }
                                ),
                              },
                            };
                          });
                        }}
                      />
                    ) : (
                      <InputRuleForm
                        inputType="TEXT"
                        name={actionType === "url" ? "url" : "phone"}
                        value={getValue(item, "value", "")}
                        placeholder={`Enter ${
                          actionType === "url" ? "URL" : "Phone Number"
                        }`}
                        label={actionType === "url" ? "URL" : "Phone"}
                        onChange={(e: any) => {
                          const { name, value } = e.target;
                          setRequest((prevRequest: any) => {
                            return {
                              ...prevRequest,
                              variableConfig: {
                                ...prevRequest.variableConfig,
                                buttons: prevRequest.variableConfig.buttons.map(
                                  (button: any, i: number) => {
                                    if (index !== i) return button;
                                    return {
                                      ...button,
                                      value: value,
                                    };
                                  }
                                ),
                              },
                            };
                          });
                        }}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          );
        }
      )}
    </div>
  );
};

export default BroadcastButtonsEdit;
