import { getValue, setValue } from "@utils/lodash";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { QueryRequestHelper } from "@common/query-request-helper";
import SlideFromRightPopup from "@components/Dialogs/SlideFromRightPopup/SlideFromRightPopup";
import { getAllSmartFilterFields } from "@services/smart-filter.service";
import {
  removeNullOrUndefinedProperties,
  sortJSONObjectArray,
} from "@common/text-helpers";
import AdvancedFilterViewStep1 from "./Step1";
import AdvancedFilterViewStep2 from "./Step2";
import SubmitContainer from "@components/common/ButtonContainer/SubmitContainer";
import AdvancedFilterViewStep from "./step";
import { useStateContext } from "@context/profileProvider";

function CommonInlineAddFilterView(props: any) {
  const {
    selectedOption,
    handleOpenFilterView,
    isOpenFilterView,
    module,
  } = props;
  const { selectedModuleId } = useStateContext();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [selectedFilterOption, setSelectedFilterOption] = useState({});
  const [request, setRequest] = useState({
    api_name: "",
    display_label: "",
    input_value_label: "",
    input_type: "",
    operator: "",
    input_value: "",
    input_value_1: "",
    custom: false,
    timezone: "",
  });

  const handleResetSelectedOptions = () => {
    setRequest({
      ...request,
      api_name: "",
      display_label: "",
      input_value_label: "",
      input_type: "",
      operator: "",
      input_value: "",
      input_value_1: "",
      custom: false,
      timezone: "",
    });
    setFilterGroupIndex(null);
    setFilterIndex(0);
    setIsEdit(false);
    setSearchText("");
    setSmartFiterFields(allFields);
    setSelectedFilterOption({});
  };
  /* -------------------------------------------------------------------------- */
  /*                              UseEffect Section                             */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (
      getValue(props, `module`, "") !== "calls" &&
      getValue(props, `module`, "") !== "meetings"
    ) {
      if (getValue(selectedOption, `module_id`, "")) {
        getSmartFields();
      } else {
        getSmartFields();
      }
    }
  }, [selectedOption]);

  useEffect(() => {
    if (getValue(props, `selectedAdvancedFiter.length`, 0) > 0) {
      setFilter_groups(getValue(props, `selectedAdvancedFiter`, []));
    } else {
      setFilter_groups([]);
    }
  }, [getValue(props, `selectedAdvancedFiter`, [])]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(false);
  const [allFields, setAllFields] = useState([]);
  const [smartFilterFields, setSmartFiterFields] = useState([]);
  const getSmartFields = async () => {
    try {
      setIsLoading(true);
      let id: any = await selectedModuleId(getValue(props, `module`, ""));

      let payload = {
        api_name: getValue(props, `module`, ""),
        module_id: getValue(selectedOption, `module_id`, "")
          ? getValue(selectedOption, `module_id`, "")
          : id,
        pipeline_id: getValue(props, `pipeline_id`, "")
          ? getValue(props, `pipeline_id`, "")
          : getValue(selectedOption, `id`, ""),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllSmartFilterFields(queryRequest);
      if (resp) {
        setAllFields(
          sortJSONObjectArray(getValue(resp, `data`, []), "seq_num")
        );
        setSmartFiterFields(
          sortJSONObjectArray(getValue(resp, `data`, []), "seq_num")
        );
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                 Search section                             */
  /* -------------------------------------------------------------------------- */
  const [searchText, setSearchText] = useState("");
  const handleChangeSearch = (e: any) => {
    const searchText = e.target.value.toLowerCase();
    setSearchText(e.target.value);
    if (e.target.value) {
      const fields = allFields.filter(
        (item: object) =>
          getValue(item, `api_name`, "") &&
          getValue(item, `api_name`, "").toLowerCase().includes(searchText)
      );
      setSmartFiterFields(fields);
    } else {
      setSmartFiterFields(allFields);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                              Condition section                             */
  /* -------------------------------------------------------------------------- */
  const [filterGroupIndex, setFilterGroupIndex] = useState<any>(null);
  const [filterIndex, setFilterIndex] = useState<any>(0);
  const [filter_groups, setFilter_groups] = useState<any>([]);
  const [isEdit, setIsEdit] = useState(false);
  const handleSave = () => {
    let req = {
      ...removeNullOrUndefinedProperties(request),
      input_value_label:
        getValue(request, `input_type`, "") === "date" ||
        getValue(request, `input_type`, "") === "time" ||
        getValue(request, `input_type`, "") === "datetime" ||
        getValue(request, `input_type`, "") === "boolean_checkbox" ||
        getValue(request, `input_type`, "") === "boolean_radio" ||
        getValue(request, `input_type`, "") === "boolean_select" ||
        getValue(request, `input_type`, "") === "picklist" ||
        getValue(request, `input_type`, "") === "select" ||
        getValue(request, `input_type`, "") === "lookup"
          ? getValue(request, `input_value_label`, "")
          : "",
    };
    if (
      getValue(request, `input_value`, "") ||
      getValue(request, `operator`, "") === "IS_EMPTY" ||
      getValue(request, `operator`, "") === "IS_NOT_EMPTY"
    ) {
      if (isEdit) {
        setFilter_groups((prevFilterGroups: any) =>
          prevFilterGroups.map((filterGroup: any, index: number) =>
            index === filterIndex
              ? {
                  filters: [req],
                }
              : filterGroup
          )
        );
      } else {
        if (filterIndex === 0 || filterIndex) {
          if (
            (filterGroupIndex === 0 || filterGroupIndex) &&
            (filterIndex === 0 || filterIndex)
          ) {
            filter_groups[filterIndex].filters.push(req);
          } else {
            filter_groups.push({
              filters: [req],
            });
          }
        } else {
          filter_groups.push({
            filters: [req],
          });
        }
      }
    }
    handleResetSelectedOptions();
    setActiveTab(0);
  };

  const handleRemoveAND = (index1: number, index2: number) => {
    let filtered = filter_groups[index1].filters.filter(
      (_item: object, index: number) => index !== index2
    );
    if (getValue(filtered, `length`, 0) > 0) {
      setValue(filter_groups, `[${index1}].filters`, filtered);
      setFilter_groups([...filter_groups]);
    } else {
      let filteredMain = filter_groups.filter(
        (_item: object, index: number) => index !== index1
      );
      setFilter_groups(filteredMain);
    }
  };

  const handleRemoveOR = (i: number) => {
    let filteredMain = filter_groups.filter(
      (_item: object, index: number) => index !== i
    );
    setFilter_groups(filteredMain);
    localStorage.setItem(
      `sf:${getValue(props, `module`, "")}`,
      JSON.stringify(filteredMain)
    );
    props.getData();
  };

  const handleCloneOR = (obj: any) => {
    filter_groups.push(obj);
    setFilter_groups([...filter_groups]);
  };
  /* -------------------------------------------------------------------------- */
  /*                                 Submit section                             */
  /* -------------------------------------------------------------------------- */

  const handleSubmit = () => {
    props.handleSubmit(filter_groups);
  };
  return (
    <div>
      <SlideFromRightPopup
        isPopupVisible={isOpenFilterView}
        addCustomClass={"rolls-popup"}
        title={"All Filters"}
        toggle={handleOpenFilterView}
        width={"520px"}
      >
        {activeTab == 0 && (
          <>
            <AdvancedFilterViewStep
              filter_groups={filter_groups}
              setActiveTab={setActiveTab}
              request={request}
              setRequest={setRequest}
              setFilterIndex={setFilterIndex}
              setFilterGroupIndex={setFilterGroupIndex}
              handleRemoveAND={handleRemoveAND}
              handleRemoveOR={handleRemoveOR}
              handleCloneOR={handleCloneOR}
              setIsEdit={setIsEdit}
              handleResetSelectedOptions={handleResetSelectedOptions}
              setSelectedFilterOption={setSelectedFilterOption}
              smartFilterFields={smartFilterFields}
            />
            <SubmitContainer
              handleBackButton={() => handleOpenFilterView()}
              handleSubmit={handleSubmit}
              submitLoading={false}
              first_title={"Cancel"}
              second_title={"Apply Filter"}
              disabled={
                getValue(filter_groups, `length`, 0) === 0 ? true : false
              }
            />
          </>
        )}

        {activeTab == 1 && (
          <AdvancedFilterViewStep1
            module={module}
            smartFilterFields={smartFilterFields}
            searchText={searchText}
            handleChangeSearch={handleChangeSearch}
            setSelectedFilterOption={setSelectedFilterOption}
            setActiveTab={setActiveTab}
            request={request}
            setRequest={setRequest}
            setFilterIndex={setFilterIndex}
            setFilterGroupIndex={setFilterGroupIndex}
          />
        )}
        {activeTab == 2 && (
          <>
            <AdvancedFilterViewStep2
              module={module}
              setActiveTab={setActiveTab}
              selectedFilterOption={selectedFilterOption}
              request={request}
              setRequest={setRequest}
              setFilterIndex={setFilterIndex}
              setFilterGroupIndex={setFilterGroupIndex}
              handleResetSelectedOptions={handleResetSelectedOptions}
            />
            <SubmitContainer
              handleBackButton={() => {
                setActiveTab(0);
                handleResetSelectedOptions();
              }}
              handleSubmit={handleSave}
              submitLoading={false}
              first_title={"Cancel"}
              second_title={isEdit ? "Update Filter" : "Save Filter"}
            />
          </>
        )}
      </SlideFromRightPopup>
    </div>
  );
}

export default CommonInlineAddFilterView;
