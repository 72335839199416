import InputRuleForm from "@components/InputRuleForm/form";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import { getValue } from "@utils/lodash";
import { Radio, Space } from "antd";
import React from "react";

const BroadcastVariables = ({
  request,
  setRequest,
  options,
  onChangeExtractType,
  uploadedFileFieldOption,
}: any) => {
  return (
    <div className="p-4">
      {getValue(request, `variableConfig.templateTags`, []).map(
        (item: any, index: any) => (
          <div
            key={index}
            className={`d-flex align-items-center gap-2 pb-3 ${
              index !== 0 ? "border-top pt-1" : ""
            }`}
          >
            {/* <Radio.Group
              options={options}
              onChange={(e: any) => onChangeExtractType(e, index)}
              value={getValue(item, `extractType`, "")}
              // optionType="button"
              // buttonStyle="solid"
            /> */}
            <Radio.Group
              onChange={(e: any) => onChangeExtractType(e, index)}
              value={getValue(item, `extractType`, "")}
            >
              <Space direction="vertical">
                {options.map((item: any, i: any) => (
                  <Radio value={item.value} key={i}>
                    {item.label}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>

            {getValue(item, `extractType`, "") === "manual" ? (
              <InputRuleForm
                // label={`{{${getValue(item, `tag`, "")}}}`}
                inputType="TEXT"
                value={
                  getValue(item, `extractType`, "") === "manual"
                    ? getValue(item, `value`, "")
                    : getValue(item, `tag`, "")
                }
                name="name"
                placeholder={`${getValue(item, `actual_tag`, "")}`}
                disabled={
                  getValue(item, `extractType`, "") !== "manual" ? true : false
                }
                onChange={(e: any) =>
                  setRequest((prevRequest: any) => {
                    return {
                      ...prevRequest,
                      variableConfig: {
                        ...prevRequest.variableConfig,
                        templateTags: prevRequest.variableConfig.templateTags.map(
                          (button: any, i: number) => {
                            if (index !== i) return button;
                            return {
                              ...button,
                              [getValue(item, `extractType`, "") === "manual"
                                ? "value"
                                : "tag"]: e.target.value,
                            };
                          }
                        ),
                      },
                    };
                  })
                }
                required
                className="mb-2"
              />
            ) : (
              <div className="w-75">
                <SearchToolTip
                  label="label"
                  value={getValue(item, `tag`, "")}
                  data={uploadedFileFieldOption}
                  placeholder="Select"
                  selectKey={"value"}
                  onChange={(e: any) =>
                    setRequest((prevRequest: any) => {
                      return {
                        ...prevRequest,
                        variableConfig: {
                          ...prevRequest.variableConfig,
                          templateTags: prevRequest.variableConfig.templateTags.map(
                            (button: any, i: number) => {
                              if (index !== i) return button;
                              return {
                                ...button,
                                tag: getValue(e, `value`, ""),
                              };
                            }
                          ),
                        },
                      };
                    })
                  }
                />
              </div>
            )}

            {/* {getValue(item, `extractType`, "") !== "manual" && (
                  <Button className="mt-3" onClick={() => handleModal(index)}>
                    Pick Variable
                  </Button>
                )} */}
          </div>
        )
      )}
    </div>
  );
};

export default BroadcastVariables;
