import React, { useEffect, useRef, useState } from "react";
import InputRuleForm from "@components/InputRuleForm/form";
import PhoneInputComponent from "@components/custom/Forms/PhoneInput";
import {
  Alert,
  Button,
  Checkbox,
  Empty,
  Image,
  Radio,
  RadioChangeEvent,
  Tag,
  Tooltip,
} from "antd";
import { getValue } from "@utils/lodash";
import "../../TemplateCreate/sampleTemplate.scss";
import DragAndDropFileComponent from "@components/custom/FileUpload/file-drag-drop";
import { config } from "../../../../../env";
import EditSvgComponent from "@assets/svg/edit";
import ReplySvgComponent from "@assets/svg/whatsapp-reply";
import CallSvgComponent from "@assets/svg/phone";
import UrlSvgComponent from "@assets/svg/custom/url";
import BroadcastVariables from "./map-variables/broadcast-variables";
import BroadcastVariableConfig from "./map-variables/variable-config";
import BroadcastMediaEdit from "./map-variables/broadcast-media-edit";
import BroadcastCarouselTab from "./map-variables/broadcast-carousel-tab";
import BroadcastButtonsEdit from "./map-variables/broadcast-buttons-edit";
import { capitalizeFirstLetterOfEachWord } from "@common/text-helpers";
import { replacePlaceholders } from "@common/markdown/markdown-helper";
import { Swiper, SwiperSlide } from "swiper/react";
import { convertCurrentDate } from "@common/date-helpers";
import MarkdownPreview from "@uiw/react-markdown-preview";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { Tabs } from "antd";
import parse from "html-to-md";

const { TabPane } = Tabs;

const TabHeaders = ({ activeTab, setActiveTab, request }: any) => {
  const [tabs, setTabs] = useState([
    { key: "1", label: "Map Columns" },
    { key: "2", label: "Variables" },
    { key: "3", label: "Media" },
    { key: "4", label: "Carousel Media" },
    { key: "5", label: "Advanced" },
  ]);

  useEffect(() => {
    if (getValue(request, `contactUploadId`, "")) {
      setTabs(
        filterTabs([
          { key: "1", label: "Map Columns" },
          { key: "3", label: "Media" },
          { key: "4", label: "Carousel Media" },
          { key: "5", label: "Advanced" },
        ])
      );
    } else {
      setTabs(
        filterTabs([
          { key: "2", label: "Variables" },
          { key: "3", label: "Media" },
          { key: "4", label: "Carousel Media" },
          { key: "5", label: "Advanced" },
        ])
      );
    }
  }, [request]);

  useEffect(() => {
    if (getValue(request, `contactUploadId`, "")) {
      const data = filterTabs([
        { key: "1", label: "Map Columns" },
        { key: "3", label: "Media" },
        { key: "4", label: "Carousel Media" },
        { key: "5", label: "Advanced" },
      ]);
      setActiveTab(getValue(data, `[${0}].key`, ""));
    } else {
      const data = filterTabs([
        { key: "2", label: "Variables" },
        { key: "3", label: "Media" },
        { key: "4", label: "Carousel Media" },
        { key: "5", label: "Advanced" },
      ]);
      setActiveTab(getValue(data, `[${0}].key`, ""));
    }
  }, []);

  const filterTabs: any = (updatedTabs: any) => {
    // Remove "Map Columns" and "Variables" tabs if buttons is an empty array
    if (
      !getValue(request, `variableConfig.templateTags`, []) ||
      getValue(request, `variableConfig.templateTags.length`, 0) === 0
    ) {
      updatedTabs = updatedTabs.filter(
        (tab: any) => tab.key !== "1" && tab.key !== "2"
      );
    }

    // Remove "Media" tab if headerLinkDetail is empty in all carousels
    const isHeaderLinkDetailEmpty = Object.values(
      getValue(request, `variableConfig.headerLinkDetail`, {})
    ).every((value) => !value);
    if (isHeaderLinkDetailEmpty) {
      updatedTabs = updatedTabs.filter((tab: any) => tab.key !== "3");
    }

    // Remove "Carousel Media" tab if carousels is an empty array
    if (getValue(request, `variableConfig.carousels.length`, 0) === 0) {
      updatedTabs = updatedTabs.filter((tab: any) => tab.key !== "4");
    }

    // Remove "Advanced" tab if templateTags is an empty array
    if (getValue(request, `variableConfig.buttons.length`, 0) === 0) {
      updatedTabs = updatedTabs.filter((tab: any) => tab.key !== "5");
    }

    return updatedTabs;
  };

  return (
    <Tabs activeKey={activeTab} onChange={setActiveTab} type="card">
      {tabs.map((tab) => (
        <TabPane tab={tab.label} key={tab.key}></TabPane>
      ))}
    </Tabs>
  );
};

const TabContent = (props: any) => {
  const {
    activeTab,
    request,
    setRequest,
    handleModal,
    handleUploadDocuments,
    showModal,
    uploadedFileFieldOption,
  } = props;
  const onChangeExtractType = (
    { target: { value } }: RadioChangeEvent,
    index: number
  ) => {
    setRequest((prevRequest: any) => {
      return {
        ...prevRequest,
        variableConfig: {
          ...prevRequest.variableConfig,
          templateTags: prevRequest.variableConfig.templateTags.map(
            (button: any, i: number) => {
              if (index !== i) return button;
              return {
                ...button,
                extractType: value,
              };
            }
          ),
        },
      };
    });
  };
  const options = [
    {
      label: "Mapping",
      value: getValue(request, `contactUploadId`, "") ? "file" : "field",
    },
    { label: "Manual", value: "manual" },
  ];
  return (
    <div className="tab-content">
      {activeTab === "1" && (
        <BroadcastVariables
          request={request}
          setRequest={setRequest}
          options={options}
          onChangeExtractType={onChangeExtractType}
          uploadedFileFieldOption={uploadedFileFieldOption}
        />
      )}

      {activeTab === "2" && (
        <BroadcastVariableConfig
          request={request}
          setRequest={setRequest}
          handleModal={handleModal}
          options={options}
          onChangeExtractType={onChangeExtractType}
        />
      )}

      {activeTab === "3" && (
        <BroadcastMediaEdit
          request={request}
          handleUploadDocuments={handleUploadDocuments}
        />
      )}

      {activeTab === "4" && (
        <BroadcastCarouselTab request={request} showModal={showModal} />
      )}

      {activeTab === "5" && (
        <BroadcastButtonsEdit request={request} setRequest={setRequest} />
      )}
    </div>
  );
};

const EditBroadcastTemplate = (props: any) => {
  const {
    request,
    setRequest,
    handleModal,
    handleUploadDocuments,
    showModal,
    uploadedFileFieldOption,
  } = props;
  const [activeTab, setActiveTab] = useState("1");
  const swiperRef = useRef(null);

  const replacePlaceholdersWithTag: any = () => {
    if (getValue(request, `body`, "")) {
      let replacedText = parse(getValue(request, `body`, ""));
      let escapedMatch = replacedText.replace(/\\/g, "");
      getValue(request, `variableConfig.templateTags`, []).forEach(
        (item: any) => {
          escapedMatch = escapedMatch.replace(
            `[[${item.actual_tag}]]`,
            `${
              getValue(item, `extractType`, "") === "manual"
                ? getValue(item, `value`, "")
                : `[[${item.tag}]]`
            }`
          );
        }
      );
      return escapedMatch;
    } else {
      return null;
    }
    // return replacedText;
  };
  return (
    <div className="edit-broadcast-template">
      <div className="mb-3 mx-5 px-5">
        <h6 className="header_text__20">Map Template Variables</h6>
      </div>
      <div
        className="mx-auto d-flex justify-content-between gap-4 w-100"
        style={{ maxWidth: "900px" }}
      >
        <div style={{ maxWidth: "600px" }} className="w-100">
          <TabHeaders
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            request={request}
          />
          <TabContent
            activeTab={activeTab}
            request={request}
            setRequest={setRequest}
            handleModal={handleModal}
            handleUploadDocuments={handleUploadDocuments}
            showModal={showModal}
            uploadedFileFieldOption={uploadedFileFieldOption}
          />
        </div>
        <div
          className="template-radio-wrapper-selected border"
          style={{ width: "304px" }}
        >
          <div>
            <div className="p-3 bg-white rounded-top-3 ">
              <h6 className="header_text__16 mb-2 position-relative pe-4">
                {capitalizeFirstLetterOfEachWord(getValue(request, "name", ""))}
              </h6>
            </div>
            <div className="template-message-container py-4">
              <div className="mx-4 bg-white p-3 template-message-body">
                <MarkdownPreview
                  source={replacePlaceholdersWithTag()}
                  className="mb-3"
                />
                {getValue(
                  request,
                  `variableConfig.headerLinkDetail.mime_type`,
                  ""
                ).includes("image") &&
                  getValue(
                    request,
                    `variableConfig.headerLinkDetail.url`,
                    ""
                  ) && (
                    <div className="d-flex justify-content-center align-items-center mb-2">
                      <img
                        src={`https://${config.URL}/${getValue(
                          request,
                          `variableConfig.headerLinkDetail.url`,
                          ""
                        )}`}
                        alt="Selected"
                        className="w-100 object-fit-cover rounded-3"
                        height={120}
                      />
                    </div>
                  )}
                {getValue(
                  request,
                  `variableConfig.headerLinkDetail.mime_type`,
                  ""
                ).includes("video") &&
                  getValue(
                    request,
                    `variableConfig.headerLinkDetail.url`,
                    ""
                  ) && (
                    <div className="d-flex justify-content-center align-items-center mb-3">
                      <video
                        autoPlay
                        loop
                        controls
                        style={{ height: "100px", width: "100%" }}
                      >
                        <source
                          src={`https://${config.URL}/${getValue(
                            request,
                            `variableConfig.headerLinkDetail.url`,
                            ""
                          )}`}
                          type="video/mp4"
                        />
                      </video>
                    </div>
                  )}
                {getValue(
                  request,
                  `variableConfig.headerLinkDetail.mime_type`,
                  ""
                ).includes("document") && (
                  <div className="d-flex justify-content-center align-items-center">
                    <img
                      src="/images/icons/document-blue.svg"
                      className="dummy-image-icon"
                    />
                  </div>
                )}
                {getValue(request, `variableConfig.buttons`, []) &&
                  getValue(request, "variableConfig.buttons", []).map(
                    (button: any, btnIndex: number) => (
                      <div key={btnIndex} className="">
                        <div className="d-flex justify-content-center align-items-center gap-2 broadcast_button py-2">
                          {getValue(button, "type", "") === "QUICK_REPLY" ? (
                            <ReplySvgComponent color="#408dfb" size={16} />
                          ) : getValue(button, "type", "") ===
                            "CALL_TO_ACTION" ? (
                            <CallSvgComponent color="#408dfb" size={14} />
                          ) : getValue(button, "type", "") === "URL" ? (
                            <UrlSvgComponent color="#408dfb" size={14} />
                          ) : null}

                          <p className="small_text__13 text-black">
                            {getValue(button, "value", "")
                              ? getValue(button, "value", "")
                              : getValue(button, "text", "")}
                          </p>
                        </div>
                      </div>
                    )
                  )}
              </div>
              {getValue(request, "variableConfig.carousels", "").length > 0 && (
                <>
                  <div className="broadcast-carousel-swiper_wrapper mx-4 mt-2">
                    <Swiper
                      spaceBetween={10}
                      slidesPerView={1.25}
                      onSlideChange={() => console.log("slide change")}
                      modules={[Navigation]}
                      navigation={{
                        prevEl: ".custom-button-prev",
                        nextEl: ".custom-button-next",
                      }}
                      ref={swiperRef}
                      className="position-relative"
                    >
                      {getValue(request, "variableConfig.carousels", "").map(
                        (carousel: any, index: number) => (
                          <SwiperSlide key={index}>
                            <div className="carousel-swiper-card">
                              {getValue(
                                carousel,
                                "headerLinkDetail.mime_type",
                                ""
                              ).includes("image") ? (
                                <img
                                  src={`https://${config.URL}/${getValue(
                                    carousel,
                                    "headerLinkDetail.url",
                                    ""
                                  )}`}
                                  alt="Carousel Image"
                                  className="broadcast-carousel-image"
                                />
                              ) : (
                                <video
                                  autoPlay
                                  className="broadcast-carousel-video"
                                >
                                  <source
                                    src={`https://${config.URL}/${getValue(
                                      carousel,
                                      "headerLinkDetail.url",
                                      ""
                                    )}`}
                                    type="video/mp4"
                                  />
                                </video>
                              )}
                              <p className="p-2 small_text__13 text-black">
                                {getValue(carousel, "body", "")}
                              </p>
                              <div className={`ms-2 d-flex flex-column gap-1`}>
                                {getValue(carousel, "buttons", []).map(
                                  (button: any, btnIndex: number) => (
                                    <div key={btnIndex}>
                                      <div className="d-flex justify-content-center align-items-center broadcast_button py-1">
                                        {getValue(button, "type", "") ===
                                        "QUICK_REPLY" ? (
                                          <ReplySvgComponent
                                            color="#408dfb"
                                            size={16}
                                          />
                                        ) : getValue(button, "type", "") ===
                                          "CALL_TO_ACTION" ? (
                                          <CallSvgComponent
                                            color="#408dfb"
                                            size={14}
                                          />
                                        ) : getValue(button, "type", "") ===
                                          "URL" ? (
                                          <UrlSvgComponent
                                            color="#408dfb"
                                            size={14}
                                          />
                                        ) : null}

                                        <p className="ms-2 small_text__12 text-black">
                                          {getValue(button, "text", "")}
                                        </p>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          </SwiperSlide>
                        )
                      )}
                    </Swiper>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditBroadcastTemplate;
