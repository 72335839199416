import "./superteam.scss";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { signUpInitiate } from "services/auth.service";
import { getValue } from "@utils/lodash";
import SignupForm from "./components/signupForm";
import TeamsList from "./components/superteam-list";
import CompanyList from "./components/company-list";
import Signup from "./components/signup-page";
import Header from "@components/common/Header/LoginHeader/header";
import timezones from "@common/json/standardized-timezone-data.json";

export default function Register() {
  const simpleValidator = useRef(new SimpleReactValidator());
  const navigate = useNavigate();
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [, forceUpdate] = useState(0);
  const [request, setRequest] = useState({
    first_name: "",
    last_name: "",
    identifier_type: "EMAIL",
    identifier: "",
    password: "",
    timezone: "",
  });

  const findTimezone: any = (timezone: string) => {
    return timezones.find(
      (item: object) => getValue(item, `value`, "") === timezone
    );
  };
  useEffect(() => {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    if (timeZone === "Asia/Calcutta") {
      setRequest({
        ...request,
        timezone: "Asia/Kolkata",
      });
    } else if (timeZone === findTimezone(timeZone)) {
      setRequest({
        ...request,
        timezone: timeZone,
      });
    } else {
      setRequest({
        ...request,
        timezone: "Asia/Kolkata",
      });
    }
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                                  API Section                               */
  /* -------------------------------------------------------------------------- */

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      setSubmitLoading(true);
      try {
        let resp = await signUpInitiate(request);
        if (resp) {
          // toast.success(getValue(resp, `message`, ""));
          navigate(`/otp/${getValue(resp, `data.verification_id`, "")}`);
          setSubmitLoading(false);
        } else {
          setSubmitLoading(false);
        }
      } catch (error) {
        setSubmitLoading(false);
      }
    }
  };

  return (
    <>
      <Header />
      <div className="container-fluid public-pages-container pb-5">
        <div className={`mobile-flex-direction d-flex pb-sm-0 pb-5 gap`}>
          <div className="flex-1">
            <div className={`back-link cursor-pointer`}>
              <Link to="/signin">
                <img
                  src="/images/icons/back-icon.svg"
                  className={`back-link-image img-fluid`}
                  alt="img"
                />
              </Link>
            </div>
            <div className={`try-superteam-wrapper d-flex`}>
              <div className={"try-superteam-wrapper__details"}>
                <h1 className={"try-superteam-wrapper__title"}>
                  Try Appzo for Free
                </h1>
                <h2
                  className={`try-superteam-wrapper__sub-title d-flex align-items-center`}
                >
                  No credit card card required. No strings attached
                </h2>
                <TeamsList />
                <CompanyList />
              </div>
            </div>
          </div>
          <div className="flex-1 d-flex d-none">
            <div className={"try-superteam-wrapper__container"}>
              <Signup />
            </div>
          </div>
          <div className="flex-1 mb-4 mb-lg-0 ">
            <SignupForm
              request={request}
              setRequest={setRequest}
              handleSubmit={handleSubmit}
              submitLoading={submitLoading}
              validator={simpleValidator}
            />
          </div>
        </div>
      </div>
    </>
  );
}
