import { useEffect, useState } from "react";
import "./task-middle.scss";
import {
  updateTask,
} from "@services/tasks.service";
import { getValue } from "@utils/lodash";
import { useLocation, useParams } from "react-router-dom";
import TasksRightSectionDetails from "@components/Pages/Pipeline/Detail/tasks";
import TaskInfo from "./components/task-info";
import { toast } from "sonner";
import DeleteModal from "@components/Dialogs/Modals/deleteModal";
import Loader from "@components/common/Loader/loading";
import { useStateContext } from "@context/profileProvider";
import {
  createTag,
  getAllTags,
  updateModuleTags,
} from "@services/tags.service";
import TagPopup from "@components/Pages/Pipeline/Detail/components/Tag";
import { convertCurrentDateWithTime } from "@common/date-helpers";
import CallSvgComponent from "@assets/svg/phone";
import {
  createNote,
  deleteNote,
  getAllNotes,
  updateNote,
} from "@services/common-notes.service";
import CommonDetailNotes from "@components/Pages/Pipeline/Detail/middle-container/Notes";

function TaskDetailMiddleSection(props: any) {
  const params = useParams();
  const { getData, info, isLoading, form, setForm } = props;
  const location = useLocation();
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                             */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getAllTagsList();
  }, [info]);
  useEffect(() => {
    getAllComments(true);
    props.getData(true);
  }, [window.location.href]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const [taskLoading, setTaskLoading] = useState(false);
  const handleTaskUpdate = async (id: string, status: boolean) => {
    try {
      setTaskLoading(true);
      let resp = await updateTask(id, {
        is_completed: status,
      });
      if (resp) {
        toast.success("Updated successfully");
        setTaskLoading(false);
        getData(false);
        props.getListData(props.selectedPipeline);
      } else {
        setTaskLoading(false);
      }
    } catch (error) {
      setTaskLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                            Comments Section                                */
  /* -------------------------------------------------------------------------- */
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const [commentLoading, setCommentLoading] = useState(false);

  const getAllComments = async (status: boolean) => {
    try {
      setCommentLoading(status);
      let resp = await getAllNotes("calls", getValue(params, `id`, ""), "");
      if (resp) {
        setComments(getValue(resp, `data.notes`, []));
        setCommentLoading(false);
      } else {
        setCommentLoading(false);
      }
    } catch (error) {
      setCommentLoading(false);
    }
  };
  const [editId, setEditId] = useState("");
  const handleEdit = (obj: object) => {
    setEditId(getValue(obj, `id`, ""));
    setComment(getValue(obj, `content`, ""));
  };
  const [loading, setLoading] = useState(false);
  const addComments = async () => {
    try {
      setLoading(true);
      let resp;
      if (editId) {
        resp = await updateNote("calls", getValue(params, `id`, ""), editId, {
          note: { content: comment },
          associations: {
            add: [
              {
                api_name: "calls",
                record_id: getValue(params, `id`, ""),
              },
            ],
          },
        });
      } else {
        resp = await createNote("calls", getValue(params, `id`, ""), {
          note: { content: comment },
          associations: {
            add: [
              {
                api_name: "calls",
                record_id: getValue(params, `id`, ""),
              },
            ],
          },
        });
      }
      if (resp) {
        toast.success(`${editId ? "Updated" : "Added"} successfully`);
        getAllComments(false);
        setComment("");
        setEditId("");
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const [deleteId, setDeleteId] = useState("");
  const [deleteValue, setDeleteValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const handleModal = () => {
    setIsOpen(!isOpen);
  };
  const handleDelete = (item: object) => {
    setDeleteId(getValue(item, `id`, ""));
    setDeleteValue(getValue(item, `content`, ""));
    handleModal();
  };
  const handleDeleteComment = async () => {
    try {
      let resp = await deleteNote(
        "calls",
        getValue(params, `id`, ""),
        deleteId
      );
      if (resp) {
        toast.success("Deleted successfully");
        handleModal();
        getAllComments(false);
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                                 Tags Section                               */
  /* -------------------------------------------------------------------------- */
  const { allModules } = useStateContext();
  const [clicked, setClicked] = useState(false);
  const [tags, setTags] = useState([]);
  const [obj, setObj] = useState({
    label: "",
    value: "",
    color_code: "#82E0AA",
  });
  const [selectedTags, setSelectedTags] = useState<any>([]);
  const [allTags, setAllTags] = useState([]);
  const getAllTagsList = async () => {
    try {
      let moduleID =
        getValue(allModules, `length`, 0) > 0
          ? allModules.find(
              (item: object) => getValue(item, `api_name`, "") === "calls"
            )
          : "";
      let resp = await getAllTags(getValue(moduleID, `id`, ""));
      if (resp) {
        setTags([]);
        let tags = getValue(resp, `data`, []);
        let selectedTags = getValue(info, `tags`, []);
        const newArray = tags.filter((item: any) =>
          selectedTags.includes(item.value)
        );
        setTags(
          newArray.map((item: object) => ({
            ...item,
            value: getValue(item, `value`, ""),
            label: getValue(item, `label`, ""),
          }))
        );

        setSelectedTags(selectedTags);
        setAllTags(
          tags.map((item: object) => ({
            ...item,
            value: getValue(item, `value`, ""),
            label: getValue(item, `label`, ""),
          }))
        );
      }
    } catch (error) {}
  };
  const [tagLoading, setTagLoading] = useState(false);
  const handleSubmitTags = async (obj: object) => {
    let moduleID =
      getValue(allModules, `length`, 0) > 0
        ? allModules.filter(
            (item: object) => getValue(item, `name`, "") === "calls"
          )
        : "";
    try {
      setTagLoading(true);
      let resp = await createTag(getValue(moduleID, `[${0}].id`, ""), obj);
      if (resp) {
        let tagsList = tags.map((item: object) => getValue(item, `value`, ""));
        let payload: any = {
          tags: tagsList.concat(getValue(obj, `value`, "")),
        };
        handleUpdateTags(payload);
        setTagLoading(false);
      } else {
        setTagLoading(false);
      }
    } catch (error) {
      setTagLoading(false);
    }
  };
  const [taagSubmitLoading, setTagSubmitLoading] = useState(false);
  const handleUpdateTags = async (payload: any) => {
    try {
      setTagSubmitLoading(true);
      let resp = await updateModuleTags("calls", getValue(params, `id`, ""), {
        tags:
          getValue(payload, `tags.length`, 0) > 0
            ? getValue(payload, `tags`, []).filter((item: string) => item)
            : selectedTags,
      });
      if (resp) {
        toast.success("Updated successfully");
        setObj({
          ...obj,
          label: "",
          value: "",
          color_code: "#D5F5E3",
        });
        props.getData(false);
        setTagSubmitLoading(false);
        setClicked(true);
      } else {
        setTagSubmitLoading(false);
      }
    } catch (error) {
      setTagSubmitLoading(false);
    }
  };
  const handleChangeTags = (item: any) => {
    const itemName = getValue(item, "value", "");
    const isTagSelected = selectedTags.includes(itemName);
    if (isTagSelected) {
      const updatedTags = selectedTags.filter(
        (tag: string) => tag !== itemName
      );
      setSelectedTags([...updatedTags]);
    } else {
      setSelectedTags([...selectedTags, itemName]);
    }
  };
  const handleChangeSelect = (value: any) => {
    let selectedValue: any = getValue(value, `value`, "");
    if (selectedTags.includes(selectedValue)) {
      let filtered = selectedTags.filter((item: any) => item !== selectedValue);
      setSelectedTags(filtered);
    } else {
      selectedTags.push(selectedValue);
      setSelectedTags([...selectedTags]);
    }
  };
  const handleAddTags = async (obj: object) => {
    let moduleID =
      getValue(allModules, `length`, 0) > 0
        ? allModules.filter(
            (item: object) => getValue(item, `name`, "") === "calls"
          )
        : "";
    try {
      let resp = await createTag(getValue(moduleID, `[${0}].id`, ""), obj);
      if (resp) {
        getTagsAfterAddedToList();
        handleChangeTags(obj);
        handleChangeSelect(obj);
      }
    } catch (error) {}
  };
  const getTagsAfterAddedToList = async () => {
    try {
      let moduleID =
        getValue(allModules, `length`, 0) > 0
          ? allModules.filter(
              (item: object) => getValue(item, `name`, "") === "calls"
            )
          : "";
      let resp = await getAllTags(getValue(moduleID, `[${0}].id`, ""));
      if (resp) {
        let tags = getValue(resp, `data`, []);
        let selectedTags = getValue(info, `tags`, []);
        setSelectedTags(selectedTags);
        setAllTags(
          tags.map((item: object) => ({
            ...item,
            value: getValue(item, `value`, ""),
            label: getValue(item, `label`, ""),
          }))
        );
      }
    } catch (error) {}
  };
  const [openEditNote, setOpenEditNote] = useState(false);
  const handleEditNote = () => {
    setOpenEditNote(!openEditNote);
  };
  return (
    <div className={"task_detail_right-container"}>
      <div
        className={`${"task_detail_right-header_section"} d-flex align-items-center`}
      >
        <div className="d-flex px-4 align-items-center justify-content-between w-100">
          <div className="d-flex">
            <div>
              <div className="d-flex align-items-center">
                {/* <img src="/images/task/icons/task.svg" /> */}
                <CallSvgComponent />
                <h6 className="header_text__18 ms-1">
                  {isLoading ? (
                    "..."
                  ) : (
                    <div className="d-flex align-items-center ms-2">
                      {convertCurrentDateWithTime(
                        getValue(info, `start_datetime`, "")
                      )}{" "}
                      {convertCurrentDateWithTime(
                        getValue(info, `end_datetime`, "")
                      ) && (
                        <>
                          {" -"}
                          {convertCurrentDateWithTime(
                            getValue(info, `end_datetime`, "")
                          )}
                        </>
                      )}
                    </div>
                  )}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={"task_detail_right-left_section_container"}>
        <div
          className={
            !props.isChanged
              ? "task_detail_right-left_section_update_section"
              : "task_detail_right-left_section"
          }
        >
          {isLoading ? (
            <Loader small={true} />
          ) : (
            <>
              <div className={`task_detail_right-left_tags pt-3 pb-3 px-3`}>
                <TagPopup
                  obj={obj}
                  setObj={setObj}
                  tagLoading={tagLoading}
                  submitLoading={taagSubmitLoading}
                  clicked={clicked}
                  setClicked={setClicked}
                  selectedTags={selectedTags}
                  handleChangeTags={handleChangeTags}
                  handleSubmitTags={handleSubmitTags}
                  handleUpdateTags={handleUpdateTags}
                  handleAddTags={handleAddTags}
                  //tags
                  values={selectedTags}
                  handleSelectChange={handleChangeSelect}
                  tags={allTags}
                  setAllTags={setAllTags}
                  tagsList={tags}
                  permissions={getValue(props, `permissions`, [])}
                />
              </div>
              <TaskInfo
                isLoading={isLoading}
                info={info}
                handleTaskUpdate={handleTaskUpdate}
                taskLoading={taskLoading}
                fields={form}
                setFields={setForm}
              />

              {/* <TaskNotes
                isLoading={loading}
                notes={comments}
                addComments={addComments}
                comment={comment}
                setComment={setComment}
                handleEdit={handleEdit}
                editId={editId}
                setEditId={setEditId}
                handleDelete={handleDelete}
              /> */}
              <div className={`${"task_detail_right-left_tags"} pb-3`}>
                <div className="d-flex align-content-center justify-content-between px-4 pt-3">
                  <h6 className="header_text__18 ">Notes</h6>
                </div>
                <div className="px-5 mt-3">
                  <CommonDetailNotes
                    module={"calls"}
                    params={params}
                    getData={props.getData}
                    permissions={getValue(props, `permissions`, [])}
                    setOpenEditNote={setOpenEditNote}
                    openEditNote={openEditNote}
                  />
                </div>
              </div>
            </>
          )}
        </div>
        <div
          className={
            !props.isChanged
              ? "task_detail_right-right_section_update_section"
              : "task_detail_right-right_section"
          }
        >
          <TasksRightSectionDetails
            data={info}
            isLoading={isLoading}
            getData={getData}
            module={"calls"}
            permissions={getValue(props, `permissions`, [])}
          />
        </div>
      </div>
      <DeleteModal
        isOpen={isOpen}
        handleModal={handleModal}
        handleSubmit={handleDeleteComment}
        deleteValue={deleteValue}
      />
    </div>
  );
}

export default TaskDetailMiddleSection;
